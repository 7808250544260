import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Radio, Input, Row, Tag, Col, Popconfirm, Select, Modal, Button, Spin, Form } from "antd";
import "antd/dist/antd.css";
import './tabs.css'
import { ApiHeader, strings, apiurl } from './data';
const locale = 'EN';
const Option = Select.Option;
const Search = Input.Search;
class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            approver: [],
            bookmark: [],
            zbookmark: [],
            car: [],
            profile: null,
            iseditcp1: false,
            iseditcp2: false,
            iseditcph1: false,
            iseditcph2: false,
            iseditlang: false,
            iseditdivision: false,
            isEditReminder:false,
            iseditemail: false,
            carNumber: '',
            isdefault: '0',
            approverId: 0,
            aid: 0,
            auserId: 0,
            isdefault1: '0',
            approverName: '',
            showmodel: false,
            approvers: [],
            isaddappr: false,
            triggerReload: false,
            showalertmodel: false,
            alertmessage: "",
            searchstr: '',
            issearching: false,
            loading: false,
        }
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 7);
        ApiHeader.userId = sessionStorage.getItem("userId");
        ApiHeader.userId = sessionStorage.getItem("userId");
        this.fetchSystemData(null);

    }

    fetchSystemData = (msg) => {
        this.setState({ loading: true })
        fetch(apiurl + 'system/list', {
            headers: ApiHeader,
            method: 'GET',
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    sessionStorage.setItem('systemdata', JSON.stringify(data.data));
                    var sd = data.data;
                    var bm = sd.bookmark;
                    var zbm = sd.zoomBookmark;
                    var resource = sd.resource;
                    for (var i = 0; i < bm.length; i++) {
                        for (var r = 0; r < resource.length; r++) {
                            if (bm[i].resourceId == resource[r].id) {
                                bm[i].name = resource[r].nameEN;
                                bm[i].resource = resource[r];
                                break;
                            }
                        }
                    }
                    var zoomAccounts = sd.zoomAccounts;
                    for (var i = 0; i < zbm.length; i++) {
                        for (var r = 0; r < zoomAccounts.length; r++) {
                            if (zbm[i].zoomAccountId == zoomAccounts[r].id) {
                                zbm[i].name = zoomAccounts[r].nameEN;
                                zbm[i].resource = zoomAccounts[r];
                                break;
                            }
                        }
                    }
                    var cars = sd.car;
                    for (var r = 0; r < cars.length; r++) {
                        if (cars[r].isDefault == 0) {
                            cars[r].isDefault = strings.no;
                        } else {
                            cars[r].isDefault = strings.yes;
                        }
                    }
                    var approver = sd.approver;
                    for (var r = 0; r < approver.length; r++) {
                        if (approver[r].isDefault == 0) {
                            approver[r].isDefault = strings.no;
                        } else {
                            approver[r].isDefault = strings.yes;
                        }
                    }


                    this.setState({ systemData: sd, approver: approver, bookmark: bm, car: cars, profile: sd.profile, zbookmark: zbm });
                    if (msg != null)
                        this.setState({ alertmessage: msg, showalertmodel: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => { this.setState({ loading: false }); console.warn(e); })
    }

    componentWillUnmount() {

    }

    removeBookmark = (b, key) => {
        var rdata = {};
        rdata.bookmarkId = b.id;
        fetch(apiurl + 'bookmark/remove', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var data = this.state.bookmark;
                    data.splice(key, 1);
                    this.setState({ bookmark: data, count: data.length });
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))

    }

    removeBookmark1 = (b, key) => {
        var rdata = {};
        rdata.bookmarkId = b.id;
        fetch(apiurl + 'bookmark/zoom/remove', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var data = this.state.zbookmark;
                    data.splice(key, 1);
                    this.setState({ zbookmark: data, count: data.length });
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))

    }

    removeCar = (c, key) => {
        var rdata = {};
        rdata.carId = c.id;
        fetch(apiurl + 'car/remove', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var data = this.state.car;
                    data.splice(key, 1);
                    this.setState({ car: data, count: data.length });
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))

    }

    removeApprover = (a, key) => {
        var rdata = {};
        rdata.approverId = a.id;
        fetch(apiurl + 'approver/remove', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var data = this.state.approver;
                    data.splice(key, 1);
                    this.setState({ approver: data, count: data.length });
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))

    }

    editprofile = (name) => {
        var rdata = {};
        rdata.id = this.state.profile.id;
        var islangchange = false;
        if (name == 'contactPerson1')
            rdata.contactPerson1 = this.state.profile.contactPerson1;
        else if (name == 'contactPerson2')
            rdata.contactPerson2 = this.state.profile.contactPerson2;
        else if (name == 'contactPhone1')
            rdata.contactPhone1 = this.state.profile.contactPhone1;
        else if (name == 'contactPhone2')
            rdata.contactPhone2 = this.state.profile.contactPhone2;
        else if (name == 'email')
            rdata.email = this.state.profile.email;
        else if (name == 'division')
            rdata.division = this.state.profile.division;
        else if (name == 'isReminder')
            rdata.isReminder = this.state.profile.isReminder;            
        else if (name == 'lang') {
            islangchange = true;
            rdata.lang = this.state.profile.lang;
        }


        fetch(apiurl + 'user/update', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    if (islangchange) {
                        this.props.setuserlang(this.state.profile.lang.toLowerCase());
                        sessionStorage.setItem("userlang", this.state.profile.lang.toLowerCase());
                    }
                    this.setState({
                        iseditcp1: false,
                        iseditcp2: false,
                        iseditcph1: false,
                        iseditcph2: false,
                        iseditlang: false,
                        iseditdivision: false,
                        isEditReminder:false,
                        iseditemail: false,
                        carNumber: '',
                        isdefault: '0',
                        approverId: 0,
                        aid: 0,
                        auserId: 0,
                        isdefault1: '0',
                        approverName: '',
                    })
                    this.fetchSystemData(strings.updatesuccess)
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }

    onCarEdit = (c, index) => {
        var cars = this.state.car;
        this.setState({ carNumber: cars[index].carNumber, isdefault: cars[index].isDefault == strings.yes ? '1' : '0' });
        var self = this;
        setTimeout(function () {
            cars[index].carNumber = <Input size="large" style={{ width: 140, height: 30 }} defaultValue={self.state.carNumber} placeholder={strings.pleasefill + ' ' + strings.carplatenumber} onChange={e => self.setState({ carNumber: e.target.value })} />;
            cars[index].isDefault = <Select size="large" style={{ width: 80, height: 30 }} defaultValue={self.state.isdefault} onChange={val => self.setState({ isdefault: val })}>
                <Option value="1">{strings.yes}</Option>
                <Option value="0">{strings.no}</Option>
            </Select>;
            cars[index].iseditcar = true;
            cars[index].isaddcar = false;
            self.setState({ car: cars });
        }, 500);

    }

    onCarSave = (c, index) => {
        var cars = this.state.car;
        var rdata = {};
        rdata.id = cars[index].id;
        rdata.userId = cars[index].userId;
        rdata.carNumber = this.state.carNumber;
        rdata.isDefault = this.state.isdefault;

        fetch(apiurl + 'car/update', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.fetchSystemData(strings.updatesuccess)
                    this.setState({
                        iseditcp1: false,
                        iseditcp2: false,
                        iseditcph1: false,
                        iseditcph2: false,
                        iseditlang: false,
                        iseditdivision: false,
                        isEditReminder:false,
                        iseditemail: false,
                        carNumber: '',
                        isdefault: '0',
                        approverId: 0,
                        aid: 0,
                        auserId: 0,
                        isdefault1: '0',
                        approverName: '',
                    })

                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }

    onCarCancle = (c, index) => {
        var cars = this.state.car;
        cars[index].carNumber = this.state.carNumber;
        cars[index].isDefault = this.state.isdefault == 0 ? strings.yes : strings.no;
        cars[index].iseditcar = false;
        cars[index].isaddcar = false;
        this.setState({ car: cars });
    }

    onCarCancle1 = (c, index) => {
        var cars = this.state.car;
        cars.splice(index, 1);
        this.setState({ car: cars, carNumber: '', isdefault: '0' });
    }

    onAddCar = () => {
        var cars = this.state.car;
        var c = {};
        c.userId = ApiHeader.userId;
        c.carNumber = <Input size="large" style={{ width: 140, height: 30 }} defaultValue={this.state.carNumber} placeholder={strings.pleasefill + ' ' + strings.carplatenumber} onChange={e => this.setState({ carNumber: e.target.value })} />;
        c.isDefault = <Select size="large" style={{ width: 80, height: 30 }} defaultValue={this.state.isdefault} onChange={val => this.setState({ isdefault: val })}>
            <Option value="1">{strings.yes}</Option>
            <Option value="0">{strings.no}</Option>
        </Select>;
        c.iseditcar = true;
        c.isaddcar = true;
        this.setState({ car: [c, ...cars] });
    }

    onCarSave1 = (c, index) => {

        var rdata = {};
        rdata.userId = ApiHeader.userId;
        rdata.carNumber = this.state.carNumber;
        rdata.isDefault = this.state.isdefault;

        fetch(apiurl + 'car/create', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.setState({
                        iseditcp1: false,
                        iseditcp2: false,
                        iseditcph1: false,
                        iseditcph2: false,
                        iseditlang: false,
                        iseditdivision: false,
                        isEditReminder:false,
                        iseditemail: false,
                        carNumber: '',
                        isdefault: '0',
                        approverId: 0,
                        aid: 0,
                        auserId: 0,
                        isdefault1: '0',
                        approverName: '',
                    })
                    this.fetchSystemData(strings.createdsuccess)
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }


    onApprEdit = (a, index) => {
        var approver = this.state.approver;
        this.setState({
            aid: approver[index].id, auserId: approver[index].userId, isaddappr: true,
            approverName: approver[index].approverName, approverId: approver[index].approverId,
            isdefault1: approver[index].isDefault == strings.yes ? '1' : '0'
        });

    }

    editApprover = () => {

        var rdata = {};
        rdata.id = this.state.aid;
        rdata.userId = this.state.auserId;
        rdata.approverId = this.state.approverId;
        rdata.isDefault = this.state.isdefault1;

        fetch(apiurl + 'approver/update', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.setState({
                        iseditcp1: false,
                        iseditcp2: false,
                        iseditcph1: false,
                        iseditcph2: false,
                        iseditlang: false,
                        iseditdivision: false,
                        isEditReminder:false,
                        iseditemail: false,
                        carNumber: '',
                        isdefault: '0',
                        approverId: 0,
                        aid: 0,
                        auserId: 0,
                        isdefault1: '0',
                        approverName: '',
                    })
                    this.fetchSystemData(strings.updatesuccess);
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }

    onApprCancle = () => {
        this.setState({ approverName: '', approverId: 0, isdefault1: '0', isaddappr: false, aid: 0, auserId: 0 });
    }

    handleOk = () => {
        this.setState({ showmodel: false });
    }

    onAddAppr = () => {
        this.setState({ isaddappr: true });
    }

    onApprSave = () => {
        if (this.state.aid != 0) {
            this.editApprover();
        } else {
            this.createApprover();
        }

    }

    createApprover = () => {
        var rdata = {};
        rdata.userId = ApiHeader.userId;
        rdata.approverId = this.state.approverId;
        rdata.isDefault = this.state.isdefault1;

        fetch(apiurl + 'approver/create', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    this.setState({
                        iseditcp1: false,
                        iseditcp2: false,
                        iseditcph1: false,
                        iseditcph2: false,
                        iseditlang: false,
                        iseditdivision: false,
                        isEditReminder:false,
                        iseditemail: false,
                        carNumber: '',
                        isdefault: '0',
                        approverId: 0,
                        aid: 0,
                        auserId: 0,
                        isdefault1: '0',
                        approverName: '',
                    })
                    this.fetchSystemData(strings.createdsuccess);
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }

    handleSearch = (e) => {
        if (!this.state.issearching && this.state.searchstr.length > 1) {
            var val = this.state.searchstr;
            this.setState({ issearching: true });
            var rdata = {};

            rdata.name = val;
            fetch(apiurl + 'user/search', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        var users = data.data.user;
                        this.setState({ approvers: users, issearching: false });
                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, issearching: false })
                    }
                    this.setState({ issearching: false })
                })
                .catch(e => { this.setState({ issearching: false }); console.warn(e) })
        } else {
            this.setState({ alertmessage: strings.enter3char, showalertmodel: true })
        }
    }

    handleselectChange = (val) => {
        if (val != '0')
            this.setState({ approverId: parseInt(val.split('-')[0]), approverName: val.split('-')[1] })
    }

    bookclick = (b) => {
        sessionStorage.setItem("bookmarkData", JSON.stringify(b))
        this.props.gotobooking();
    }

    bookclick1 = (b) => {
        sessionStorage.setItem("zbookmarkData", JSON.stringify(b))
        this.props.gotobooking1();
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    render() {
        return (
            <div className='Tab1Div'>
                <Spin spinning={this.state.loading}>
                    <Row lg={24}>
                        <Col lg={16}>
                            <div className="boxSetting" style={{ marginLeft: '1%', width: '98%' }}>
                                <Row>
                                    <Col lg={12}>
                                        <span className="TitleOfTheBox">{strings.bookmark1}</span>
                                        <div style={{ marginTop: 13 }}>
                                            <span className="SmallTextRoom">{strings.room}</span>
                                        </div>
                                        <div style={{ height: 80, overflowY: 'auto' }}>
                                            {this.state.bookmark.length == 0 ? <span className="SmallTextRoom" style={{ marginLeft: 100, fontSize: 18 }}>{strings.nodata}</span> : ''}
                                            {this.state.bookmark.map((b, index) => <div style={{ marginBottom: 5 }}>
                                                <Row md={24}>
                                                    <Col md={12}>
                                                        <span className="yellowTextRoom">{b.name}</span>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Tag color={'#455773'} style={{ fontWeight: 500, float: 'right' }} onClick={() => this.bookclick(b)}>{strings.book1}</Tag>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.removeBookmark(b, index)}>
                                                            {/* <span className="Removiebtn">REMOVE</span> */}
                                                            <Tag color={'#455773'} style={{ fontWeight: 500 }}>{strings.remove1}</Tag>
                                                        </Popconfirm>
                                                    </Col>
                                                </Row>
                                            </div>)}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <span className="TitleOfTheBox">{' '}</span>
                                        <div style={{ marginTop: 52 }}>
                                            <span className="SmallTextRoom">{strings.zoom}</span>
                                        </div>
                                        <div style={{ height: 80, overflowY: 'auto' }}>
                                            {this.state.zbookmark.length == 0 ? <span className="SmallTextRoom" style={{ marginLeft: 100, fontSize: 18 }}>{strings.nodata}</span> : ''}
                                            {this.state.zbookmark.map((b, index) => <div style={{ marginBottom: 5 }}>
                                                <Row md={24}>
                                                    <Col md={12}>
                                                        <span className="yellowTextRoom">{b.name}</span>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Tag color={'#455773'} style={{ fontWeight: 500, float: 'right' }} onClick={() => this.bookclick1(b)}>{strings.book1}</Tag>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.removeBookmark1(b, index)}>
                                                            {/* <span className="Removiebtn">REMOVE</span> */}
                                                            <Tag color={'#455773'} style={{ fontWeight: 500 }}>{strings.remove1}</Tag>
                                                        </Popconfirm>
                                                    </Col>
                                                </Row>
                                            </div>)}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="boxSetting">
                                <span className="TitleOfTheBox">{strings.contactinfo}</span>
                                <Row>
                                    <Col md={12}>
                                        <div style={{ marginTop: 13 }}>
                                            <span className="SmallTextRoom">{strings.contactperson}</span>
                                            <div style={{ marginTop: 2 }}>
                                                <Row md={24}>
                                                    <Col md={14}>
                                                        {this.state.iseditcp1 ? <Input style={{ width: 140 }} value={this.state.profile.contactPerson1} placeholder={strings.pleasefill + ' ' + strings.contactperson1} onChange={e => { var p = this.state.profile; p.contactPerson1 = e.target.value; this.setState({ profile: p }) }} />
                                                            : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.contactPerson1}</span>}
                                                    </Col>
                                                    <Col md={10}>
                                                        {this.state.iseditcp1 ? <Tag color={'#455773'} onClick={() => this.editprofile('contactPerson1')} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                            : <Tag color={'#455773'} onClick={() => this.setState({ iseditcp1: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div style={{ marginTop: 13 }}>
                                            <span className="SmallTextRoom">{strings.contactphone}</span>
                                            <div style={{ marginTop: 2 }}>
                                                <Row md={24}>
                                                    <Col md={14}>
                                                        {this.state.iseditcph1 ? <Input style={{ width: 140 }} value={this.state.profile.contactPhone1} placeholder={strings.pleasefill + ' ' + strings.contactphone1} onChange={e => { var p = this.state.profile; p.contactPhone1 = e.target.value; this.setState({ profile: p }) }} />
                                                            : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.contactPhone1}</span>}
                                                    </Col>
                                                    <Col md={10}>
                                                        {this.state.iseditcph1 ? <Tag color={'#455773'} onClick={() => this.editprofile('contactPhone1')} style={{ fontWeight: 500 }}>{strings.save1} </Tag>
                                                            : <Tag color={'#455773'} onClick={() => this.setState({ iseditcph1: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div style={{ marginTop: 13 }}>
                                            <div style={{ marginTop: 2 }}>
                                                <Row md={24}>
                                                    <Col md={14}>
                                                        {this.state.iseditcp2 ? <Input style={{ width: 140 }} value={this.state.profile.contactPerson2} placeholder={strings.pleasefill + ' ' + strings.contactPerson2} onChange={e => { var p = this.state.profile; p.contactPerson2 = e.target.value; this.setState({ profile: p }) }} />
                                                            : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.contactPerson2}</span>}
                                                    </Col>
                                                    <Col md={10}>
                                                        {this.state.iseditcp2 ? <Tag color={'#455773'} onClick={() => this.editprofile('contactPerson2')} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                            : <Tag color={'#455773'} onClick={() => this.setState({ iseditcp2: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div style={{ marginTop: 13 }}>
                                            <div style={{ marginTop: 2 }}>
                                                <Row md={24}>
                                                    <Col md={14}>
                                                        {this.state.iseditcph2 ? <Input style={{ width: 140 }} value={this.state.profile.contactPhone2} placeholder={strings.pleasefill + ' ' + strings.contactphone2} onChange={e => { var p = this.state.profile; p.contactPhone2 = e.target.value; this.setState({ profile: p }) }} />
                                                            : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.contactPhone2}</span>}
                                                    </Col>
                                                    <Col md={10}>
                                                        {this.state.iseditcph2 ? <Tag color={'#455773'} onClick={() => this.editprofile('contactPhone2')} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                            : <Tag color={'#455773'} onClick={() => this.setState({ iseditcph2: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div></Col>

                    </Row>
                    <Row lg={24} style={{ marginTop: 12 }}>
                        <Col lg={8}>
                            <div className="boxSetting" >
                                <span className="TitleOfTheBox">{strings.userprofile}</span>
                                <div style={{ marginTop: 13 }}>

                                    <span className="SmallTextRoom">{strings.email}</span>
                                    <div style={{ marginTop: 2 }}>
                                        <Row md={24}>
                                            <Col md={15}>
                                                {this.state.iseditemail ? <Input size="large" style={{ width: 180 }} value={this.state.profile.email} placeholder={strings.pleasefill + ' ' + strings.email} onChange={e => { var p = this.state.profile; p.email = e.target.value; this.setState({ profile: p }) }} />
                                                    : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.email}</span>}
                                            </Col>
                                            <Col md={9}>
                                                {this.state.iseditemail ? <Tag color={'#455773'} onClick={() => this.editprofile('email')} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                    : <Tag color={'#455773'} onClick={() => this.setState({ iseditemail: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div style={{ marginTop: 13 }}>
                                    <span className="SmallTextRoom">{strings.language}</span>
                                    <div style={{ marginTop: 2 }}>
                                        <Row md={24}>
                                            <Col md={15}>
                                                {this.state.iseditlang ? <Select size="large" style={{ width: 180 }} value={this.state.profile.lang} onChange={val => { var p = this.state.profile; p.lang = val; this.setState({ profile: p }) }}>
                                                    <Option value="EN">EN</Option>
                                                    <Option value="ZH">ZH</Option>
                                                </Select>
                                                    : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.lang}</span>}
                                            </Col>
                                            <Col md={9}>
                                                {this.state.iseditlang ? <Tag color={'#455773'} onClick={() => this.editprofile('lang')} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                    : <Tag color={'#455773'} onClick={() => this.setState({ iseditlang: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div style={{ marginTop: 13 }}>
                                    <span className="SmallTextRoom">{strings.division}</span>
                                    <div style={{ marginTop: 2 }}>
                                        <Row md={24}>
                                            <Col md={15}>
                                                {this.state.iseditdivision ? <Select size="large" style={{ width: 180 }} value={this.state.profile.division} onChange={val => { var p = this.state.profile; p.division = val; this.setState({ profile: p }) }}>
                                                    {strings.divisions.map((d) => <Option key={d} value={d}>{d}</Option>)}
                                                </Select>
                                                    : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.division}</span>}
                                            </Col>
                                            <Col md={9}>
                                                {this.state.iseditdivision ? <Tag color={'#455773'} onClick={() => this.editprofile('division')} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                    : <Tag color={'#455773'} onClick={() => this.setState({ iseditdivision: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div style={{ marginTop: 13 }}>
                                    <span className="SmallTextRoom">{strings.isreminder}</span>
                                    <div style={{ marginTop: 2 }}>
                                        <Row md={24}>
                                            <Col md={15}>
                                                {this.state.isEditReminder ? <Select size="large" style={{ width: 180 }} value={this.state.profile.isReminder} onChange={val => { var p = this.state.profile; p.isReminder = val; this.setState({ profile: p }) }}>
                                                    <Option value={'Y'}>{strings.yes}</Option>
                                                    <Option value={'N'}>{strings.no}</Option>
                                                </Select>
                                                    : <span className="yellowTextRoom">{this.state.profile == null ? '' : this.state.profile.isReminder==='Y'?strings.yes:strings.no}</span>}
                                            </Col>
                                            <Col md={9}>
                                                {this.state.isEditReminder ? <Tag color={'#455773'} onClick={() => this.editprofile('isReminder')} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                    : <Tag color={'#455773'} onClick={() => this.setState({ isEditReminder: true })} style={{ fontWeight: 500 }}>{strings.edit1}</Tag>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className="boxSetting">
                                <span className="TitleOfTheBox">{strings.carnumber}</span>
                                <div style={{ marginTop: 13 }}>
                                    <Row md={24}>
                                        <Col md={8}>
                                            <span className="SmallTextRoom">{strings.carnumber}</span>
                                        </Col>
                                        <Col md={5}>
                                            <span className="SmallTextRoom">{strings.default}</span>
                                        </Col>
                                    </Row>
                                    <div style={{ height: 70, overflowY: 'auto' }}>
                                        {this.state.car.map((c, index) =>
                                            <Row md={24} style={{ marginTop: 2, marginBottom: 12 }}>
                                                <Col md={8}>
                                                    <span className="yellowTextRoom">{c.carNumber}</span>
                                                </Col>
                                                <Col md={5}>
                                                    <span className="yellowTextRoom" >{c.isDefault}</span>
                                                </Col>
                                                {c.iseditcar ? (c.isaddcar ? <Col md={11}>
                                                    <Tag color={'#455773'} onClick={() => this.onCarSave1(c, index)} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                    <Tag color={'#455773'} onClick={() => this.onCarCancle1(c, index)} style={{ fontWeight: 500 }}>{strings.cancel}</Tag>
                                                </Col> : <Col md={11}>
                                                    <Tag color={'#455773'} onClick={() => this.onCarSave(c, index)} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                    <Tag color={'#455773'} onClick={() => this.onCarCancle(c, index)} style={{ fontWeight: 500 }}>{strings.cancel}</Tag>
                                                </Col>) : <Col md={11}>
                                                    {/* <Tag color={'#455773'} onClick={()=>this.onCarEdit(c,index)} style={{fontWeight:500}}>EDIT</Tag> */}
                                                    <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.removeCar(c, index)}>
                                                        <Tag color={'#455773'} style={{ fontWeight: 500 }}>{strings.remove1}</Tag>
                                                    </Popconfirm>
                                                </Col>}
                                            </Row>
                                        )}
                                    </div>
                                </div>
                                <Tag color={'#455773'} onClick={() => this.onAddCar()} style={{ fontWeight: 500 }}>{strings.add1}</Tag>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="boxSetting">

                                <span className="TitleOfTheBox">{strings.approver}</span>
                                <div style={{ marginTop: 13 }}>
                                    <Row md={24}>
                                        <Col md={8}>
                                            <span className="SmallTextRoom">{strings.postname1}</span>
                                        </Col>
                                        <Col md={5}>
                                            <span className="SmallTextRoom">{strings.default}</span>
                                        </Col>
                                    </Row>
                                    <div style={{ height: 70, overflowY: 'auto' }}>
                                        <Row md={24} style={{ marginTop: 2, marginBottom: 12 }} hidden={!this.state.isaddappr}>
                                            <Col md={8}>
                                                <Input size="large" style={{ width: 140, height: 30 }} value={this.state.approverName} placeholder={strings.pleasesearchapprover} disabled />
                                            </Col>
                                            <Col md={5}>
                                                <Select size="large" style={{ width: 80, height: 30 }} value={this.state.isdefault1} onChange={val => this.setState({ isdefault1: val })}>
                                                    <Option value="1">{strings.yes}</Option>
                                                    <Option value="0">{strings.no}</Option>
                                                </Select>
                                            </Col>
                                            <Col md={11}>
                                                <Tag color={'#455773'} onClick={() => this.onApprSave()} style={{ fontWeight: 500 }}>{strings.save1}</Tag>
                                                <Tag color={'#455773'} onClick={() => this.setState({ showmodel: true })} style={{ fontWeight: 500 }}>{strings.search1}</Tag>
                                                <Tag color={'#455773'} onClick={() => this.onApprCancle()} style={{ fontWeight: 500 }}>{strings.cancel}</Tag>
                                            </Col>
                                        </Row>
                                        {this.state.approver.map((a, index) =>
                                            <Row md={24} style={{ marginTop: 2, marginBottom: 12 }}>
                                                <Col md={8}>
                                                    <span className="yellowTextRoom">{a.approverName}</span>
                                                </Col>
                                                <Col md={5}>
                                                    <span className="yellowTextRoom" >{a.isDefault}</span>
                                                </Col>
                                                <Col md={11}>
                                                    {/* <Tag color={'#455773'} onClick={()=>this.onApprEdit(a,index)} style={{fontWeight:500}}>EDIT</Tag> */}
                                                    <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.removeApprover(a, index)}>
                                                        <Tag color={'#455773'} style={{ fontWeight: 500 }}>{strings.remove1}</Tag>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        )}</div>
                                </div>
                                <Tag color={'#455773'} onClick={() => this.onAddAppr()} style={{ fontWeight: 500 }}>{strings.add1}</Tag>
                            </div>
                        </Col>
                    </Row>
                </Spin>
                <Modal
                    title={strings.searchapprover}
                    visible={this.state.showmodel}
                    onOk={this.handleOk}
                    onCancel={() => this.setState({ showmodel: false })}
                    footer={[

                        <Button key="submit" className='subscribe1' type="danger" onClick={this.handleOk}>
                            {strings.select}
                        </Button>,
                    ]}
                >
                    <div style={{ paddingTop: 5 }}>
                        <Row>
                            <span style={{ color: '#ff7f27', fontSize: 14 }}>{strings.plsincludeslashinsearch}</span>
                        </Row>
                        <Row type="flex" style={{ marginTop: 15 }}>
                            <Col span={6}>{strings.postname1}</Col>
                            <Col span={18}>
                                <Form>
                                    <Row type="flex">
                                        <Col span={17}>
                                            <Input value={this.state.searchstr} placeholder={strings.enter3char} onChange={e => this.setState({ searchstr: e.target.value })} />
                                        </Col>
                                        <Col span={6}>
                                            <Button type="danger" className='subscribe1' htmlType="submit" style={{ marginLeft: 15, marginTop: 0 }} disabled={this.state.issearching} loading={this.state.issearching} onClick={(e) => this.handleSearch(e)}>
                                                {strings.search}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>                                
                            </Col>
                        </Row>
                        <Row type="flex" style={{ marginTop: 15 }}>
                            <Col span={6}>{strings.user}</Col>
                            <Col span={18}>
                                <Select defaultValue="0" style={{ width: '100%' }} onChange={this.handleselectChange}>
                                    <Option value="0">{strings.pleaseselect}</Option>
                                    {this.state.approvers.map(d => <Option key={d.id + '-' + d.name}>{d.name} ({d.post})</Option>)}

                                </Select>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
            </div >
        )
    }
}

export default Setting;
