import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Table,Tag,Button, Modal} from "antd";
import './App.css';
import moment from 'moment';
import {ApiHeader,strings,apiurl,RESOURCE_TYPES} from './data';
const locale = 'EN';

class AdminPendingForAction extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            systemData:null,
            loading:false, 
            loading1:false,            
            triggerReload:false,
            showalertmodel:false,
            alertmessage:"",
            roomdata:[],
            parkingdata:[],
            parkingdata1:[],
        }

        this.columns = [            
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },/* {
                title: strings.location,
                dataIndex: 'location1',
                key: 'location1',
                width:'15%',
                sorter: (a, b) => a.location1.localeCompare(b.location1, 'en', { numeric: true }),
            }, */{
                title:strings.requestedby,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'20%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.reason,
                dataIndex: 'reason',
                key: 'reason',
                width:'20%',
                sorter: (a, b) => a.reason.localeCompare(b.reason, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag} onClick={()=>this.ontagclick({tag},record)}>{tag.toUpperCase()}</Tag>;
                    })}
                    </span>
                ),
            }
        ];
        this.columns1 = [
            {
                title: strings.time,
                dataIndex: 'date',
                key: 'date',
                width:'20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'),'pm'))
            },{
                title: strings.location,
                dataIndex: 'location1',
                key: 'location1',
                width:'15%',
                sorter: (a, b) => a.location1.localeCompare(b.location1, 'en', { numeric: true }),
            },{
                title: strings.requestedby,
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                width:'20%',
                
                sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy, 'en', { numeric: true }),
            },{
                title: strings.subject,
                dataIndex: 'subject',
                key: 'subject',
                width:'20%',
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            },{
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                    {tags.map(tag => {
                        return <Tag color={'#455773'} key={tag} style={{marginTop:5}} onClick={()=>this.ontagclick1({tag},record)}>{tag.toUpperCase()}</Tag>;
                    })}
                    </span>
                ),
            }
        ];
    
        
    }

    

    componentDidMount() {
        ApiHeader.userId = sessionStorage.getItem("userId");
        this.setState({systemData:this.props.sd});
        this.setState({selectedUser:this.props.su});
        this.searchPendingCarpark(this.props.su.id,this.props.su.role);
        this.searchtranferBooking(this.props.su.id);
    }
  
    componentWillUnmount() {
  
    }

    componentWillReceiveProps = (nextProps) => {
        
        if(nextProps.su.id != this.state.selectedUser.id){
            this.setState({selectedUser:nextProps.su});
            this.searchPendingCarpark(nextProps.su.id,nextProps.su.role);
            this.searchtranferBooking(nextProps.su.id);
        }        
    }
    
    searchPendingCarpark=(id,role)=>{
        this.setState({loading:true,parkingdata:[],parkingdata1:[],})
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;        
        rdata.userId = id;
        rdata.state = 'pending_approval';
        
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;
                    
                    var bs1 = [];
                    var resource = this.state.systemData.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        
                        b1.date = ds;
                        b1.tags = [strings.approve, strings.reject];
                        b1.requestedBy = bs[i].userName;
                        b1.reason = bs[i].reason;
                        b1.state = bs[i].state;
                        b1.groupName = bs[i].groupName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';                        
                        b1.remark = bs[i].remark;     
                        b1.staffType = bs[i].staffType;                   
                        b1.type = strings.operational;
                        b1.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.isElectric=bs[i].isElectric===null?'':bs[i].isElectric;
                        b1.visitorCompany=bs[i].visitorCompany===null?'':bs[i].visitorCompany;
                        b1.visitorName=bs[i].visitorName===null?'':bs[i].visitorName;
                        b1.purpose=bs[i].purpose===null?'':bs[i].purpose;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                    b1.location1 = resource[j].locationEN.split(',')[0]+" "+resource[j].locationEN.split(',')[1];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                    b1.location1 = resource[j].locationZH.split(',')[0]+" "+resource[j].locationZH.split(',')[1];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({loading:false,});
                    if(role == 'admin'){
                        this.searchPendingCarpark1(id,bs1)
                    }                        
                    else{
                        this.setState({parkingdata:bs1,loading:false});
                    }

                
                }else{
                    this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))

        var rdata1 = {};
        rdata1.resourceType = RESOURCE_TYPES.VISITOR_CARPARK;        
        rdata1.userId = id;
        rdata1.state = 'pending_approval';
        
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST',   
            credentials: 'same-origin',
            body: JSON.stringify(rdata1),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;
                    
                    var bs1 = [];
                    var resource = this.state.systemData.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        
                        b1.date = ds;
                        b1.tags = [strings.approve, strings.reject];
                        b1.requestedBy = bs[i].userName;
                        b1.reason = bs[i].reason;
                        b1.state = bs[i].state;
                        b1.groupName = bs[i].groupName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';                        
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;                        
                        b1.type = strings.operational;
                        b1.resourceType = RESOURCE_TYPES.VISITOR_CARPARK;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.isElectric=bs[i].isElectric===null?'':bs[i].isElectric;
                        b1.visitorCompany=bs[i].visitorCompany===null?'':bs[i].visitorCompany;
                        b1.visitorName=bs[i].visitorName===null?'':bs[i].visitorName;
                        b1.purpose=bs[i].purpose===null?'':bs[i].purpose;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                    b1.location1 = resource[j].locationEN.split(',')[0]+" "+resource[j].locationEN.split(',')[1];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                    b1.location1 = resource[j].locationZH.split(',')[0]+" "+resource[j].locationZH.split(',')[1];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({parkingdata1:bs1,loading:false});
                
                }else{
                    this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false})
        })
        .catch(e => console.warn(e))
    }

    searchPendingCarpark1=(id,bokkings)=>{
        this.setState({loading:true,parkingdata:[]})
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;        
        rdata.userId = id;
        rdata.state = 'pending_admin';
        
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST',  
            credentials: 'same-origin', 
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;
                    
                    var bs1 = [];
                    var resource = this.state.systemData.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        
                        b1.date = ds;
                        b1.tags = [strings.approve, strings.reject];
                        b1.requestedBy = bs[i].userName;
                        b1.reason = bs[i].reason;
                        b1.state = bs[i].state;
                        b1.groupName = bs[i].groupName;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';                        
                        b1.remark = bs[i].remark;   
                        b1.staffType = bs[i].staffType;                     
                        b1.type = strings.operational;
                        b1.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.isElectric=bs[i].isElectric===null?'':bs[i].isElectric;
                        b1.visitorCompany=bs[i].visitorCompany===null?'':bs[i].visitorCompany;
                        b1.visitorName=bs[i].visitorName===null?'':bs[i].visitorName;
                        b1.purpose=bs[i].purpose===null?'':bs[i].purpose;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                    b1.location1 = resource[j].locationEN.split(',')[0]+" "+resource[j].locationEN.split(',')[1];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                    b1.location1 = resource[j].locationZH.split(',')[0]+" "+resource[j].locationZH.split(',')[1];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    var pd = bokkings.concat(bs1)
                    this.setState({parkingdata:pd,loading:false});
                    
                
                }else{
                    this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
                    this.setState({parkingdata:bokkings,loading:false});
                }
                this.setState({loading:false})
        })
        .catch(e => {console.warn(e);this.setState({parkingdata:bokkings,loading:false});})
    }

    
    searchtranferBooking=(id)=>{
        this.setState({loading1:true,roomdata:[]})
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.MEETING_ROOM;        
        rdata.userId = id;
        rdata.state = 'pending_accept';
        fetch(apiurl+'booking/action', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',   
            body: JSON.stringify(rdata),         
        }).then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
                if(data.code == "1"){
                    var bs = data.data.bookings;                    
                    var bs1 = [];
                    var resource = this.state.systemData.resource;
                    for(var i=0;i<bs.length;i++){
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        var ds = moment.unix((bs[i].startTime/1000)).format("YYYY-MM-DD hh:mmA") +"-"+moment.unix((bs[i].endTime/1000)).format("hh:mmA");
                        
                        b1.date = ds;
                        b1.tags = [strings.accept,strings.decline];
                        b1.requestedBy = bs[i].userName;
                        b1.subject = bs[i].subject;
                        b1.state = bs[i].state;
                        b1.firstcontact = bs[i].contactPerson1 +'('+bs[i].contactPhone1+')';
                        b1.secondcontact = bs[i].contactPerson2 +'('+bs[i].contactPhone2+')';                        
                        b1.subject = bs[i].subject;
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.chairman = bs[i].chairman;
                        for(var j=0;j<resource.length;j++){
                            if(resource[j].id === bs[i].resourceId){
                                if(locale ==='EN'){  
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];                                  
                                    b1.location1 = resource[j].locationEN.split(',')[0]+" "+resource[j].locationEN.split(',')[1];
                                }else{
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                    b1.location1 = resource[j].locationZH.split(',')[0]+" "+resource[j].locationZH.split(',')[1];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({roomdata:bs1,count1:bs1.length,loading1:false});
                    
                
                }else{
                    this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
                }
                this.setState({loading1:false})
        })
        .catch(e => console.warn(e))
    }

    ontagclick=(tag,b)=>{
        if(tag.tag == strings.approve){            
            this.approveBooking(b.id);
        }else if(tag.tag == strings.reject){
           this.rejectBooking(b.id);
        }else if(tag.tag == strings.view){
            this.setState({isediting:true,isbooking:false,selectedBooking:b,iseditop:false});
        }          
    }

    ontagclick1=(tag,b)=>{
        if(tag.tag == strings.accept){            
            this.acceptBooking(b.id);
        }else if(tag.tag == strings.decline){
            this.declineBooking(b.id);
        }else if(tag.tag == strings.view){
            this.setState({isediting:true,isbooking:true,selectedBooking:b,iseditop:false});
        }         
    }

    editback=()=>{
                   
        this.setState({isediting:false,iseditop:false,isbooking:true})
    }

    approveBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/approve', {
        headers: ApiHeader,
        method: 'POST',
        credentials: 'same-origin',   
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            
            if(data.code == "1"){
                this.setState({alertmessage:strings.approvedsuccess,showalertmodel:true})
                this.searchPendingCarpark(this.props.su.id,this.props.su.role);
            }else{
                this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }
    
    rejectBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/reject', {
        headers: ApiHeader,
        method: 'POST',  
        credentials: 'same-origin', 
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            
            if(data.code == "1"){
                this.setState({alertmessage:strings.rejectedsuccess,showalertmodel:true})
                this.searchPendingCarpark(this.props.su.id);
            }else{
                this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }

    acceptBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/accept', {
        headers: ApiHeader,
        method: 'POST', 
        credentials: 'same-origin',  
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            
            if(data.code == "1"){
                this.setState({alertmessage:strings.acceptedsuccess,showalertmodel:true})
                this.searchtranferBooking(this.props.su.id);
            }else{
                this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }
    
    declineBooking=(id)=>{
        
        var rdata = {};
        rdata.bookingId=id;
        fetch(apiurl+'booking/decline', {
        headers: ApiHeader,
        method: 'POST',  
        credentials: 'same-origin', 
        body: JSON.stringify(rdata), 
        }).then(response => {
            if (response.ok) {
            return response.json();
            } else {
            throw new Error('Something went wrong ...');
            }
        })
        .then(data =>{
            
            if(data.code == "1"){
                this.setState({alertmessage:strings.declinedsuccess,showalertmodel:true})
                this.searchtranferBooking(this.props.su.id);
            }else{
                this.setState({alertmessage:strings.errorcode+" "+data.error_code,showalertmodel:true})
            }
        })
        .catch(e => console.warn(e))
        
    }

    findErrorMessage=(msg)=>{
        return strings.errorcodes[msg.split(' : ')[1]] != null ?strings.errorcodes[msg.split(' : ')[1]]:msg;
    }

    

    render() {
    
    
        return (
            
            <div>                               
                <div style={{marginTop: 20,marginLeft:10}}><span style={{fontSize:15,fontWeight:500,color:'#455773'}}>{strings.pendingtransferacceptance}</span></div>
                <div style={{marginTop:10}}>
                    <div className="table-operations">
                    </div>
                    <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns1} loading={this.state.loading1} pagination={{ pageSize: 5 }} dataSource={this.state.roomdata} />
                    <div className="CountOfData">
                        <span className="CountText">{strings.totalcount} {this.state.roomdata.length}</span>
                    </div>        
                </div>
                <div style={{marginTop:40,marginLeft:10}}><span style={{fontSize:15,fontWeight:500,color:'#455773'}}>{strings.pendingtransfercarparkapproval}</span></div>
                <div style={{marginTop:10}}>
                    <div className="table-operations">
                    </div>
                    <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns} loading={this.state.loading} pagination={{ pageSize: 5 }} dataSource={this.state.parkingdata} />
                    <div className="CountOfData">
                        <span className="CountText">{strings.totalcount} {this.state.parkingdata.length}</span>
                    </div>        
                </div>
                <div style={{marginTop:40,marginLeft:10}}><span style={{fontSize:15,fontWeight:500,color:'#455773'}}>{strings.pendingvisitorapproval}</span></div>
                <div style={{marginTop:10}}>
                    <div className="table-operations">
                    </div>
                    <Table style={{marginTop:12,backgroundColor:'#fff'}} columns={this.columns} loading={this.state.loading} pagination={{ pageSize: 5 }} dataSource={this.state.parkingdata1} />
                    <div className="CountOfData">
                        <span className="CountText">{strings.totalcount} {this.state.parkingdata1.length}</span>
                    </div>        
                </div>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={()=>this.setState({showalertmodel:false})}
                    footer={[                  
                        <Button key="submit" className='subscribe1' type="danger" onClick={()=>{this.setState({showalertmodel:false});if(this.state.triggerReload)window.location.reload();}}>
                        {strings.ok}
                        </Button>,
                    ]}
                    >
                        <span className="TitleOfTheBox">{strings.errormessage}</span><br/>
                        <span className="TitleOfTheBox1">{this.state.showalertmodel?this.findErrorMessage(this.state.alertmessage):''}</span>
                </Modal>
            </div>
        );
    }
}    
export default AdminPendingForAction;

