import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Row, Col, Table, Select, Tag, Button, Radio, Popconfirm, Input, Modal, Tooltip, message, Form, TimePicker, DatePicker } from "antd";
import './App.css';
import moment from 'moment';
import EditBooking from './EditBooking';
import EditCarpark from './EditCarpark';
import EditZoomBooking from './EditZoomBooking';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
const Option = Select.Option;

const Search = Input.Search;
const locale = 'EN';

class MyBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            resource: null,
            roomdata: [],
            parkingdata: [],
            count: 0,
            count1: 0,
            selectedBooking: null,
            isediting: false,
            iseditop: true,
            loading: false,
            radiooption: 'a',
            isbooking: true,
            showmodel: false,
            tuserId: 0,
            approvers: [],
            showalertmodel: false,
            alertmessage: "",
            issearching: false,
            istransfering: false,
            searchstr: '',
            zoomdata: [],
            count2: 0,
            zoomaccounts: [],
            isZoombooking: false,
            showStartMeetingModal: false,
            selectedMeetingurl: '',
            selectedMeetingemail: '',
            selectedMeetingpassword: '',
            isregistrants: false,
            isShortenModel: false,
            availableTimeslots:[],
            selectedTimeslot:'',
        }

        this.columns = [
            {
                title: strings.date,
                dataIndex: 'date',
                key: 'date',
                width: '20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm'))
            }, {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width: '10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width: '10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, {
                title: strings.status,
                dataIndex: 'status',
                key: 'status',
                width: '8%',
                sorter: (a, b) => a.status.localeCompare(b.status, 'en', { numeric: true }),
            }, {
                title: strings.subject,
                dataIndex: 'subject',
                key: 'subject',
                width: '15%',
                render: (val, record) => (<p className='tablelongtext'>{val}</p>),
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            }, {
                title: strings.remark,
                dataIndex: 'remark',
                key: 'remark',
                width: '15%',
                render: (val, record) => (<p className='tablelongtext'>{val}</p>),
                sorter: (a, b) => a.remark.localeCompare(b.remark, 'en', { numeric: true }),
            }, {
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                        {tags.map(tag => {
                            return (tag != strings.cancel1 ? tag === strings.shortenextend ?<Tag color={'#ff234c'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick({ tag }, record)}>{tag.toUpperCase()}</Tag>:
                            <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick({ tag }, record)}>{tag.toUpperCase()}</Tag> :
                                <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.confirm(record)}>
                                    <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} >{tag.toUpperCase()}</Tag>
                                </Popconfirm>
                            );
                        })}
                    </span>
                ),
            }
        ];

        this.columns1 = [
            {
                title: strings.date,
                dataIndex: 'date',
                key: 'date',
                width: '20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm'))
            },/* {
                title: strings.floor,
                dataIndex: 'floor',
                key: 'floor',
                width:'10%',
                sorter: (a, b) => a.floor.localeCompare(b.floor, 'en', { numeric: true }),
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width:'10%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
            }, */{
                title: strings.status,
                dataIndex: 'status',
                key: 'status',
                width: '20%',
                sorter: (a, b) => a.status.localeCompare(b.status, 'en', { numeric: true }),
            }, {
                title: strings.reason,
                dataIndex: 'reason',
                key: 'reason',
                width: '25%',
                render: (val, record) => (<p className='tablelongtext' style={{ width: 450 }}>{val}</p>),
                sorter: (a, b) => a.reason.localeCompare(b.reason, 'en', { numeric: true }),
            }, {
                title: strings.type,
                dataIndex: 'type',
                key: 'type',
                width: '15%',
                sorter: (a, b) => a.type.localeCompare(b.type, 'en', { numeric: true }),
            }, {
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                        {tags.map(tag => {
                            return (tag != strings.cancel1 ? 
                                tag === strings.shortenextend ?<Tag color={'#ff234c'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick1({ tag }, record)}>{tag.toUpperCase()}</Tag>:
                                <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick1({ tag }, record)}>{tag.toUpperCase()}</Tag> :
                                <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.confirm1(record)}>
                                    <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }}>{tag.toUpperCase()}</Tag>
                                </Popconfirm>
                            );
                        })}
                    </span>
                ),
            }
        ];

        this.columns2 = [
            {
                title: strings.date,
                dataIndex: 'date',
                key: 'date',
                width: '10%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm'))
            }, {
                title: strings.type,
                dataIndex: 'resourceType',
                key: 'resourceType',
                width: '10%',
                sorter: (a, b) => a.resourceType.localeCompare(b.resourceType, 'en', { numeric: true }),
            }, {
                title: strings.meetingorwebinarid,
                dataIndex: 'zoomMeetingId',
                key: 'zoomMeetingId',
                width: '10%',
                sorter: (a, b) => a.zoomMeetingId.localeCompare(b.zoomMeetingId, 'en', { numeric: true }),
            }, {
                title: strings.meetingorwebinarpassword,
                dataIndex: 'zoomMeetingPassword',
                key: 'zoomMeetingPassword',
                width: '12%',
                render: (a, b) => <Tooltip title={a}><span style={{ cursor: 'pointer' }}>{'******'}</span></Tooltip>
            }, {
                title: strings.status,
                dataIndex: 'status',
                key: 'status',
                width: '10%',
                sorter: (a, b) => a.status.localeCompare(b.status, 'en', { numeric: true }),
            }, {
                title: strings.subject,
                dataIndex: 'subject',
                key: 'subject',
                width: '10%',
                render: (val, record) => (<p className='tablelongtext' style={{ width: 200 }}>{val}</p>),
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            }, {
                title: strings.hostemailaddress,
                dataIndex: 'zoomEmail',
                key: 'zoomEmail',
                width: '10%',
                sorter: (a, b) => a.zoomEmail.localeCompare(b.zoomEmail, 'en', { numeric: true }),
            }, {
                title: strings.hostpassword,
                dataIndex: 'zoomAccountPassword',
                key: 'zoomAccountPassword',
                width: '10%',
                render: (a, b) => <Tooltip title={a}><span style={{ cursor: 'pointer' }}>{'********'}</span></Tooltip>
            }, {
                title: strings.remark,
                dataIndex: 'remark',
                key: 'remark',
                width: '10%',
                render: (val, record) => (<p className='tablelongtext' style={{ width: 200 }}>{val}</p>),
                sorter: (a, b) => a.remark.localeCompare(b.remark, 'en', { numeric: true }),
            }, {
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                        {tags.map(tag => {
                            return (tag != strings.cancel1 ?
                                tag === strings.shortenextend ?<Tag color={'#ff234c'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick2({ tag }, record)}>{tag.toUpperCase()}</Tag>:
                                tag == strings.copymeetingurl ?
                                    <Tooltip title={record.zoomJoinUrl}><Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick2({ tag }, record)}>{tag.toUpperCase()}</Tag></Tooltip>
                                    : tag == strings.startmeeting ? <Tooltip title={record.zoomJoinUrl}><Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick2({ tag }, record)}>{tag.toUpperCase()}</Tag></Tooltip>
                                        : tag == strings.registrants ? <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick2({ tag }, record)}>{tag.toUpperCase()}</Tag> : <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }} onClick={() => this.ontagclick2({ tag }, record)}>{tag.toUpperCase()}</Tag> :
                                <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.confirm2(record)}>
                                    <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }}>{tag.toUpperCase()}</Tag>
                                </Popconfirm>
                            );
                        })}
                    </span>
                ),
            }
        ];
    }

    confirm = (b) => {
        this.removeBooking(b.id, b.key1);
    }

    confirm1 = (b) => {
        this.removeBooking1(b.id, b.key1);
    }

    confirm2 = (b) => {
        this.removeBooking2(b.id, b.key1);
    }

    componentDidMount() {
        sessionStorage.setItem('tabNumber', 4);
        ApiHeader.userId = sessionStorage.getItem("userId");
        var d = sessionStorage.getItem('systemdata');
        var sd = JSON.parse(d);
        this.setState({ resource: sd.resource, zoomaccounts: sd.zoomAccounts, systemData: sd });
        this.searchBooking('current');
        this.searchCarBooking('current');
        this.searchZoomBooking('current');
    }

    componentWillUnmount() {

    }

    searchCarBooking = (type) => {
        this.setState({ loading1: true, parkingdata: [], count1: 0 })
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.VISITOR_CARPARK;
        rdata.userId = ApiHeader.userId;
        if ('current' === type) {
            rdata.state = ['booked', 'confirmed', 'pending_approval'];
            rdata.sort = 'asc';
        }
        else {
            rdata.state = ['completed', 'rejected', 'manually_cancelled', 'admin_cancelled', 'system_checkout'];
            rdata.sort = 'desc';
        }

        fetch(apiurl + 'booking/list', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var bss = data.data.bookings;
                    var bs = [];
                    for (var i = 0; i < bss.length; i++) {
                        bs = bs.concat(bss[i].booking)
                    }
                    var bs1 = [];
                    var resource = this.state.resource;

                    for (var i = 0; i < bs.length; i++) {
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.unixstarttime = bs[i].startTime;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var cd = moment.now()

                        var ds = moment.unix((bs[i].startTime / 1000)).format("YYYY-MM-DD hh:mmA") + "-" + moment.unix((bs[i].endTime / 1000)).format("hh:mmA");

                        b1.date = ds;

                        var tags = [];
                        if ((bs[i].state == 'pending_approval' || bs[i].state == 'pending_admin') && 'current' === type) {
                            if (cd >= bs[i].startTime && cd <= bs[i].endTime) {
                                b1.tags = [strings.view];
                            } else if (cd <= bs[i].startTime) {
                                b1.tags = [strings.view, strings.cancel1];
                            } else {
                                b1.tags = [strings.view];
                            }
                        }
                        else if ('current' === type) {
                            if (bs[i].state == 'booked' && cd >= bs[i].startTime && cd <= bs[i].endTime) {
                                b1.tags = [strings.view, strings.cancel1, strings.shortenextend];
                            } else if (cd <= bs[i].startTime) {
                                b1.tags = [strings.view, strings.edit, strings.cancel1];
                            } else {
                                b1.tags = [strings.view];
                            }
                        }
                        else
                            b1.tags = [strings.view];

                        b1.firstcontact = bs[i].contactPerson1 + '(' + bs[i].contactPhone1 + ')';
                        b1.secondcontact = bs[i].contactPerson2 + '(' + bs[i].contactPhone2 + ')';
                        b1.resourceType = RESOURCE_TYPES.VISITOR_CARPARK;
                        b1.type = strings.visitor;
                        b1.carNumber = bs[i].carNumber;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.reason = bs[i].reason;
                        b1.remark = bs[i].remark;
                        
                        b1.state = bs[i].state;
                        b1.isReminder = bs[i].isReminder;
                        b1.isElectric = bs[i].isElectric === null ? '' : bs[i].isElectric;
                        b1.visitorCompany = bs[i].visitorCompany === null ? '' : bs[i].visitorCompany;
                        b1.visitorName = bs[i].visitorName === null ? '' : bs[i].visitorName;
                        b1.purpose = bs[i].purpose === null ? '' : bs[i].purpose;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;

                        if (bs[i].state == 'completed' || bs[i].state == 'system_checkout')
                            b1.status = strings.completed;
                        else if (bs[i].state == 'manually_cancelled' || bs[i].state == 'admin_cancelled')
                            b1.status = strings.cancelled;
                        else if (bs[i].state == 'rejected')
                            b1.status = strings.rejected;
                        else if (bs[i].state == 'booked' || bs[i].state == 'confirmed')
                            b1.status = strings.booked;
                        else if (bs[i].state == 'pending_approval')
                            b1.status = strings.pfaba;
                        else if (bs[i].state == 'pending_admin')
                            b1.status = strings.pfabemu;

                        b1.groupName = bs[i].groupName;
                        for (var j = 0; j < resource.length; j++) {
                            if (resource[j].id === bs[i].resourceId) {
                                if (locale === 'EN') {
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                } else {
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({ parkingdata: this.state.parkingdata.concat(bs1), loading1: false });
                    this.setState({ count1: this.state.parkingdata.length })
                    this.searchCarBooking1(type);
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading1: false })
            })
            .catch(e => console.warn(e))
    }

    searchCarBooking1 = (type) => {
        this.setState({ loading1: true })
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;
        rdata.userId = ApiHeader.userId;
        if ('current' === type) {
            rdata.state = ['booked', 'confirmed', 'pending_approval', 'pending_admin'];
            rdata.sort = 'asc';
        }
        else {
            rdata.state = ['completed', 'rejected', 'manually_cancelled', 'admin_cancelled', 'system_checkout'];
            rdata.sort = 'desc';
        }

        fetch(apiurl + 'booking/list', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var bss = data.data.bookings;
                    var bs = [];
                    for (var i = 0; i < bss.length; i++) {
                        bs = bs.concat(bss[i].booking)
                    }
                    var bs1 = [];
                    var resource = this.state.resource;

                    for (var i = 0; i < bs.length; i++) {
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.unixstarttime = bs[i].startTime;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime / 1000)).format("YYYY-MM-DD hh:mmA") + "-" + moment.unix((bs[i].endTime / 1000)).format("hh:mmA");

                        b1.date = ds;
                        var cd = moment.now();
                        if ((bs[i].state == 'pending_approval' || bs[i].state == 'pending_admin') && 'current' === type) {
                            if (cd >= bs[i].startTime && cd <= bs[i].endTime) {
                                b1.tags = [strings.view];
                            } else if (cd <= bs[i].startTime) {
                                b1.tags = [strings.view, strings.cancel1];
                            } else {
                                b1.tags = [strings.view];
                            }
                        }
                        else if ('current' === type) {
                            if (bs[i].state == 'booked' && cd >= bs[i].startTime && cd <= bs[i].endTime) {
                                b1.tags = [strings.view, strings.cancel1, strings.shortenextend];
                            } else if (cd <= bs[i].startTime) {
                                b1.tags = [strings.view, strings.edit, strings.cancel1];
                            } else {
                                b1.tags = [strings.view];
                            }
                        }
                        else
                            b1.tags = [strings.view];


                        b1.firstcontact = bs[i].contactPerson1 + '(' + bs[i].contactPhone1 + ')';
                        b1.secondcontact = bs[i].contactPerson2 + '(' + bs[i].contactPhone2 + ')';
                        b1.resourceType = RESOURCE_TYPES.OPERATIONAL_CARPARK;
                        b1.type = strings.operational;
                        b1.reason = bs[i].reason;
                        b1.remark = bs[i].remark;
                        b1.staffType = bs[i].staffType;
                        b1.state = bs[i].state;
                        b1.isReminder = bs[i].isReminder;
                        if (bs[i].state == 'completed' || bs[i].state == 'system_checkout')
                            b1.status = strings.completed;
                        else if (bs[i].state == 'manually_cancelled' || bs[i].state == 'admin_cancelled')
                            b1.status = strings.cancelled;
                        else if (bs[i].state == 'rejected')
                            b1.status = strings.rejected;
                        else if (bs[i].state == 'booked' || bs[i].state == 'confirmed')
                            b1.status = strings.booked;
                        else if (bs[i].state == 'pending_approval')
                            b1.status = strings.pfaba;
                        else if (bs[i].state == 'pending_admin')
                            b1.status = strings.pfabemu;

                        b1.carNumber = bs[i].carNumber;
                        b1.approverName = bs[i].approverName;
                        b1.approverId = bs[i].approverId;
                        b1.groupName = bs[i].groupName;
                        b1.division = bs[i].division;
                        b1.isMechanized = bs[i].isMechanized;
                        for (var j = 0; j < resource.length; j++) {
                            if (resource[j].id === bs[i].resourceId) {
                                if (locale === 'EN') {
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                } else {
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    var bs1 = this.state.parkingdata.concat(bs1);
                    if ('current' === type)
                        bs1 = bs1.sort((a, b) => a.unixstarttime - b.unixstarttime);
                    else
                        bs1 = bs1.sort((a, b) => b.unixstarttime - a.unixstarttime);
                    this.setState({ parkingdata: bs1, loading1: false, count1: bs1.length });

                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading1: false })
            })
            .catch(e => console.warn(e))
    }

    searchBooking = (type) => {
        this.setState({ loading: true, roomdata: [], count: 0 })
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.MEETING_ROOM;
        rdata.userId = ApiHeader.userId;
        if ('current' === type) {
            rdata.state = ['booked', 'confirmed', 'pending_accept'];
            rdata.sort = 'asc';
        }
        else {
            rdata.state = ['completed', 'rejected', 'manually_cancelled', 'admin_cancelled', 'system_checkout'];
            rdata.sort = 'desc';
        }

        fetch(apiurl + 'booking/list', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var bss = data.data.bookings;
                    var bs = [];
                    for (var i = 0; i < bss.length; i++) {
                        bs = bs.concat(bss[i].booking)
                    }
                    if ('current' === type)
                        bs = bs.sort((a, b) => a.startTime - b.startTime);
                    else
                        bs = bs.sort((a, b) => b.startTime - a.startTime);
                    var bs1 = [];
                    var resource = this.state.resource;
                    for (var i = 0; i < bs.length; i++) {
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime / 1000)).format("YYYY-MM-DD hh:mmA") + "-" + moment.unix((bs[i].endTime / 1000)).format("hh:mmA");

                        b1.date = ds;
                        var cd = moment.now();
                        var tags = [];
                        tags.push(strings.view);
                        if ('current' === type && bs[i].state != 'pending_accept') {
                            if (bs[i].state == 'booked' && cd >= bs[i].startTime && cd <= bs[i].endTime) {
                                tags.push(strings.cancel1);
                                tags.push(strings.shortenextend);
                            } else if (cd <= bs[i].startTime) {
                                tags = [strings.view, strings.edit, strings.cancel1, strings.transfer];
                            }
                        }
                        b1.tags = tags;
                        b1.firstcontact = bs[i].contactPerson1 + '(' + bs[i].contactPhone1 + ')';
                        b1.secondcontact = bs[i].contactPerson2 + '(' + bs[i].contactPhone2 + ')';
                        b1.subject = bs[i].subject;
                        b1.remark = bs[i].remark;
                        b1.state = bs[i].state;
                        b1.isReminder = bs[i].isReminder;
                        b1.resourceType = RESOURCE_TYPES.MEETING_ROOM;
                        if (bs[i].state == 'completed' || bs[i].state == 'system_checkout')
                            b1.status = strings.completed;
                        else if (bs[i].state == 'manually_cancelled' || bs[i].state == 'admin_cancelled')
                            b1.status = strings.cancelled;
                        else if (bs[i].state == 'rejected')
                            b1.status = strings.rejected;
                        else if (bs[i].state == 'booked' || bs[i].state == 'confirmed')
                            b1.status = strings.booked;
                        else if (bs[i].state == 'pending_accept')
                            b1.status = strings.pendingaccept;
                        b1.chairman = bs[i].chairman;
                        b1.groupName = bs[i].groupName;
                        for (var j = 0; j < resource.length; j++) {
                            if (resource[j].id === bs[i].resourceId) {
                                if (locale === 'EN') {
                                    b1.floor = resource[j].locationEN.split(',')[1];
                                    b1.location = resource[j].locationEN.split(',')[0];
                                } else {
                                    b1.floor = resource[j].locationZH.split(',')[1];
                                    b1.location = resource[j].locationZH.split(',')[0];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({ roomdata: bs1, count: bs1.length, loading: false });


                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }

    searchZoomBooking = (type) => {
        this.setState({ loading: true, zoomdata: [], count2: 0 })
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.ZOOM_MEETING;
        rdata.userId = ApiHeader.userId;
        if ('current' === type) {
            rdata.state = ['booked', 'confirmed', 'pending_accept'];
            rdata.sort = 'asc';
        }
        else {
            rdata.state = ['completed', 'rejected', 'manually_cancelled', 'admin_cancelled', 'system_checkout'];
            rdata.sort = 'desc';
        }

        fetch(apiurl + 'booking/list', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var bss = data.data.bookings;
                    var bs = [];
                    for (var i = 0; i < bss.length; i++) {
                        bs = bs.concat(bss[i].booking)
                    }

                    this.searchZoomBooking1(type, bs)


                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }

    searchZoomBooking1 = (type, bookings) => {

        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.ZOOM_WEBINAR;
        rdata.userId = ApiHeader.userId;
        if ('current' === type) {
            rdata.state = ['booked', 'confirmed', 'pending_accept'];
            rdata.sort = 'asc';
        }
        else {
            rdata.state = ['completed', 'rejected', 'manually_cancelled', 'admin_cancelled', 'system_checkout'];
            rdata.sort = 'desc';
        }

        fetch(apiurl + 'booking/list', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var bss = data.data.bookings;
                    var bs = [];
                    for (var i = 0; i < bss.length; i++) {
                        bs = bs.concat(bss[i].booking)
                    }
                    bs = bs.concat(bookings);
                    if ('current' === type)
                        bs = bs.sort((a, b) => a.startTime - b.startTime);
                    else
                        bs = bs.sort((a, b) => b.startTime - a.startTime);

                    var bs1 = [];
                    var zoomaccounts = this.state.zoomaccounts;
                    for (var i = 0; i < bs.length; i++) {
                        var b1 = {};
                        b1.key = bs[i].id;
                        b1.key1 = i;
                        b1.id = bs[i].id;
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        var ds = moment.unix((bs[i].startTime / 1000)).format("YYYY-MM-DD hh:mmA") + "-" + moment.unix((bs[i].endTime / 1000)).format("hh:mmA");

                        b1.date = ds;
                        var cd = moment.now();
                        var tags = [];
                        tags.push(strings.view);
                        if ('current' === type && bs[i].state != 'pending_accept') {
                            if (bs[i].state == 'booked' && cd >= bs[i].startTime && cd <= bs[i].endTime) {
                                tags.push(strings.cancel1);
                                tags.push(strings.shortenextend);
                            } else if (cd <= bs[i].startTime) {
                                tags = [strings.view, strings.edit, strings.cancel1, strings.copymeetingurl, strings.startmeeting];
                            }
                        }
                        b1.userId = bs[i].userId;
                        b1.tags = tags;
                        b1.isReminder = bs[i].isReminder;
                        b1.firstcontact = bs[i].contactPerson1 + '(' + bs[i].contactPhone1 + ')';
                        b1.secondcontact = bs[i].contactPerson2 + '(' + bs[i].contactPhone2 + ')';
                        b1.subject = bs[i].subject;
                        b1.remark = bs[i].remark;
                        b1.state = bs[i].state;
                        b1.zoomMeetingPassword = bs[i].zoomMeetingPassword;
                        b1.zoomMeetingId = bs[i].zoomMeetingId;
                        b1.zoomJoinUrl = bs[i].zoomJoinUrl;
                        b1.setting = bs[i].setting;
                        b1.zoomMeetingStatus = bs[i].zoomMeetingStatus;
                        b1.zoomDialinNo = bs[i].zoomDialinNo;
                        b1.zoomAccountPassword = bs[i].zoomAccountPassword;
                        for (var j = 0; j < zoomaccounts.length; j++) {
                            if (bs[i].zoomAccountId === zoomaccounts[j].id) {
                                b1.zoomEmail = zoomaccounts[j].accountEmail;
                                b1.resource = zoomaccounts[j];
                                b1.rtype = zoomaccounts[j].resourceType;
                                b1.resourceType = zoomaccounts[j].resourceType === RESOURCE_TYPES.ZOOM_MEETING ? strings.meeting : strings.webinar;
                                break;
                            }
                        }

                        if (bs[i].state == 'completed' || bs[i].state == 'system_checkout')
                            b1.status = strings.completed;
                        else if (bs[i].state == 'manually_cancelled' || bs[i].state == 'admin_cancelled')
                            b1.status = strings.cancelled;
                        else if (bs[i].state == 'rejected')
                            b1.status = strings.rejected;
                        else if (bs[i].state == 'booked' || bs[i].state == 'confirmed')
                            b1.status = strings.booked;
                        else if (bs[i].state == 'pending_accept')
                            b1.status = strings.pendingaccept;
                        b1.chairman = bs[i].chairman;
                        b1.groupName = bs[i].groupName;

                        bs1.push(b1);

                    }
                    this.setState({ zoomdata: bs1, count2: bs1.length, loading: false });


                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }



    ontagclick = (tag, b) => {
        if (tag.tag == strings.transfer) {
            this.setState({ showmodel: true, selectedBooking: b });
        } else if (tag.tag == strings.edit) {
            this.setState({ isediting: true, isbooking: true, selectedBooking: b, iseditop: true });
        } else if (tag.tag == strings.view) {
            this.setState({ isediting: true, isbooking: true, selectedBooking: b, iseditop: false });
        } else if (tag.tag === strings.shortenextend){
            this.setState({ selectedBooking: b, isShortenModel:true, selectedTimeslot:moment.unix((b.endTime / 1000)).format('HH:mm') });
            this.getAvailableTimeSlots(b);
        }

    }

    getAvailableTimeSlots=(b)=>{
        this.setState({ loading1: true})
        var rdata = {};
        rdata.bookingId = b.id;
        fetch(apiurl + 'booking/getAvailableTimeSlots', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {
                    var availableTimeslots = data.data.timeSlots;                    
                    this.setState({ availableTimeslots: availableTimeslots })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading1: false })
            })
            .catch(e => {this.setState({ loading1: false });console.warn(e);})
    }

    ontagclick1 = (tag, b) => {
        if (tag.tag == strings.cancel1) {
        } else if (tag.tag == strings.edit) {
            this.setState({ isediting: true, isbooking: false, selectedBooking: b, iseditop: true });
        } else if (tag.tag == strings.view) {
            this.setState({ isediting: true, isbooking: false, selectedBooking: b, iseditop: false });
        } else if (tag.tag === strings.shortenextend){
            this.setState({ selectedBooking: b, isShortenModel:true, selectedTimeslot:moment.unix((b.endTime / 1000)).format('HH:mm'), });
            this.getAvailableTimeSlots(b);
        }
    }


    ontagclick2 = (tag, b) => {
        if (tag.tag == strings.edit) {
            this.setState({ isediting: true, isZoombooking: true, isbooking: false, selectedBooking: b, iseditop: true });
            setTimeout(() => {
                var ele = window.document.getElementById('maintabdiv');
                if (ele != null)
                    ele.scrollTop = 0;
            }, 100)
        } else if (tag.tag == strings.view) {
            this.setState({ isediting: true, isZoombooking: true, isbooking: false, selectedBooking: b, iseditop: false });
            setTimeout(() => {
                var ele = window.document.getElementById('maintabdiv');
                if (ele != null)
                    ele.scrollTop = 0;
            }, 100)
        } else if (tag.tag == strings.copymeetingurl) {
            //navigator.clipboard.writeText(b.zoomJoinUrl);
            var input = document.createElement('textarea');
            input.innerHTML = b.zoomJoinUrl;
            document.body.appendChild(input);
            input.select();
            var result = document.execCommand('copy');
            document.body.removeChild(input);
            message.success(strings.cipiedsuccess);
        } else if (tag.tag == strings.startmeeting) {
            this.setState({
                selectedMeetingurl: b.zoomJoinUrl, showStartMeetingModal: true, selectedMeetingemail: b.zoomEmail,
                selectedMeetingpassword: b.zoomAccountPassword
            })
        } else if (tag.tag == strings.registrants) {
            this.setState({ isediting: true, isZoombooking: true, isbooking: false, selectedBooking: b, isregistrants: true });
            setTimeout(() => {
                var ele = window.document.getElementById('maintabdiv');
                if (ele != null)
                    ele.scrollTop = 0;
            }, 100)
        } else if (tag.tag === strings.shortenextend){
            this.setState({ selectedBooking: b, isShortenModel:true, selectedTimeslot:moment.unix((b.endTime / 1000)).format('HH:mm') });
            this.getAvailableTimeSlots(b);
        }

    }

    redirectToMeetingUrl = () => {
        var joinurl = this.state.selectedMeetingurl.replace('https://', 'zoommtg://');
        var jus = joinurl.split('/j/');
        var jus1 = jus[1].split('?');

        var zoomurl = jus[0] + '/join?action=join&confno=' + jus1[0] + '?' + jus1[1];
        var win = window.open(zoomurl, '_blank');
        win.focus();
        this.setState({ showStartMeetingModal: false })
    }

    removeBooking = (id, key) => {
        var rdata = {};
        rdata.bookingId = id;
        fetch(apiurl + 'booking/cancel', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var data = this.state.roomdata;
                    data.splice(key, 1);
                    for (var i = 0; i < data.length; i++) {
                        data[i].key1 = i;
                    }
                    this.setState({ roomdata: data, count: data.length });
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }

    removeBooking1 = (id, key) => {
        var rdata = {};
        rdata.bookingId = id;
        fetch(apiurl + 'booking/cancel', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var data = this.state.parkingdata;
                    data.splice(key, 1);
                    for (var i = 0; i < data.length; i++) {
                        data[i].key1 = i;
                    }
                    this.setState({ parkingdata: data, count1: data.length });
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }

    removeBooking2 = (id, key) => {
        var rdata = {};
        rdata.bookingId = id;
        fetch(apiurl + 'zoom/booking/cancel', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var data = this.state.zoomdata;
                    data.splice(key, 1);
                    for (var i = 0; i < data.length; i++) {
                        data[i].key1 = i;
                    }
                    this.setState({ zoomdata: data, count2: data.length });
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }

    editback = () => {
        if (this.state.radiooption == 'a') {
            this.searchBooking('current');
        }
        else {
            this.searchBooking('history');
        }
        this.setState({ isediting: false, iseditop: true, isbooking: true })
    }

    editback1 = () => {
        if (this.state.radiooption == 'a') {
            this.searchCarBooking('current');
        }
        else {
            this.searchCarBooking('history');
        }
        this.setState({ isediting: false, iseditop: true, isbooking: true })
    }

    editback2 = () => {
        this.setState({ isediting: false, iseditop: true, isbooking: true, isZoombooking: false })
        if (this.state.radiooption == 'a') {
            this.searchZoomBooking('current');
        }
        else {
            this.searchZoomBooking('history');
        }
        
    }

    onRadioChange = (e) => {
        if (e.target.value == 'a') {
            this.setState({ radiooption: 'a' })
            this.searchBooking('current');
            this.searchCarBooking('current');
            this.searchZoomBooking('current');
        } else {
            this.setState({ radiooption: 'b' })
            this.searchBooking('history');
            this.searchCarBooking('history');
            this.searchZoomBooking('history');
        }
    }

    handleOk = () => {
        if (!this.state.istransfering && this.state.tuserId != 0) {
            this.setState({ istransfering: true });
            var rdata = {};
            rdata.bookingId = this.state.selectedBooking.id;
            rdata.userId = this.state.tuserId;
            fetch(apiurl + 'booking/transfer', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        this.setState({ showmodel: false });
                        this.setState({ alertmessage: strings.transfersuccess, showalertmodel: true, istransfering: false, })
                        this.searchBooking('current');
                        this.searchCarBooking('current');
                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, istransfering: false, showalertmodel: true })
                    }
                })
                .catch(e => {
                    this.setState({ istransfering: false });
                    console.warn(e)
                })
        }
    }

    handleselectChange = (val) => {
        if (val != '0') {
            this.setState({ tuserId: val });
        }

    }

    handleSearch = () => {
        if (!this.state.issearching && this.state.searchstr.length > 1) {
            var val = this.state.searchstr;
            this.setState({ issearching: true });
            var rdata = {};
            rdata.name = val;
            fetch(apiurl + 'user/search1', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        var users = data.data.user;
                        this.setState({ approvers: users, issearching: false });
                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, issearching: false })
                    }
                    this.setState({ issearching: false })
                })
                .catch(e => { console.warn(e); this.setState({ issearching: false }) })
        } else {
            this.setState({ alertmessage: strings.enter3char, showalertmodel: true })
        }
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    extendBooking=()=>{
        if(this.state.selectedBooking.endTime <=  this.state.selectedBooking.startTime){
            message.warn(strings.errorcodes.B014)
            return;
        }
        if (this.state.selectedBooking.rtype === RESOURCE_TYPES.ZOOM_MEETING || this.state.selectedBooking.rtype === RESOURCE_TYPES.ZOOM_WEBINAR){
            var rdata = {};
            rdata.startTime = this.state.selectedBooking.startTime;
            rdata.endTime = moment(moment.unix((this.state.selectedBooking.endTime / 1000)).format('YYYY-MM-DD')+" "+this.state.selectedTimeslot,'YYYY-MM-DD HH:mm').valueOf();
            rdata.id = this.state.selectedBooking.id;
            fetch(apiurl + 'zoom/booking/editEndTime', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {
                this.setState({ loading: false })
                if (data.code == "1") {
                    this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true, isShortenModel:false })
                    this.searchZoomBooking('current');
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
        }else{
            var rdata = {};
            rdata.startTime = this.state.selectedBooking.startTime;
            rdata.endTime = moment(moment.unix((this.state.selectedBooking.endTime / 1000)).format('YYYY-MM-DD')+" "+this.state.selectedTimeslot,'YYYY-MM-DD HH:mm').valueOf();
            rdata.id = this.state.selectedBooking.id;
            fetch(apiurl + 'booking/editEndTime', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {
                this.setState({ loading: false })
                if (data.code == "1") {
                    this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true, isShortenModel:false })
                    this.searchBooking('current');
                    this.searchCarBooking('current');
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
        }
    }

    range=(start, end)=>{
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
    }
      
    disabledHours=()=>{
        const hours = this.range(0, 24);  
        var hs = moment.unix((this.state.selectedBooking.startTime / 1000)).format('HH');
        if(this.state.selectedBooking.resourceType === RESOURCE_TYPES.MEETING_ROOM){
            var allwoedHours = hours.filter((h)=>h<parseInt(hs) || h<8 || h>21);
            return allwoedHours;
        }else if(this.state.selectedBooking.resourceType === RESOURCE_TYPES.VISITOR_CARPARK){
            var allwoedHours = hours.filter((h)=>h<parseInt(hs) || h<8 || h>21);
            return allwoedHours;
        }else if(this.state.selectedBooking.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK){
            var allwoedHours = hours.filter((h)=>h<parseInt(hs) || h<8 || h>18);
            return allwoedHours;
        }else if(this.state.selectedBooking.rtype === RESOURCE_TYPES.ZOOM_WEBINAR || this.state.selectedBooking.rtype === RESOURCE_TYPES.ZOOM_MEETING){
            var allwoedHours = hours.filter((h)=>h<parseInt(hs) || h<8 || h>21);
            return allwoedHours;
        }        
    }

    disabledMinutes=(h)=>{
        const minutes = [15,30,45];  
        if(this.state.selectedBooking.resourceType === RESOURCE_TYPES.MEETING_ROOM){
            if(h===21){
                return minutes;
            }else{
                return [];
            }
        }else if(this.state.selectedBooking.resourceType === RESOURCE_TYPES.VISITOR_CARPARK){
            if(h===21){
                return minutes;
            }else{
                return [];
            }
        }else if(this.state.selectedBooking.resourceType === RESOURCE_TYPES.OPERATIONAL_CARPARK){
            if(h===18){
                return [45];
            }else{
                return [];
            }
        }else if(this.state.selectedBooking.rtype === RESOURCE_TYPES.ZOOM_WEBINAR || this.state.selectedBooking.rtype === RESOURCE_TYPES.ZOOM_MEETING){
            if(h===21){
                return minutes;
            }else{
                return [];
            }
        } 
    }

    onEtChange=(val)=>{
        if(val !== null){
            this.setState({selectedBooking:{...this.state.selectedBooking, endTime:val.unix()*1000}})
        }
        
    }
    
    render() {
        const {selectedBooking=null} = this.state;

        return (
            <div className='Tab2Div' id="maintabdiv">
                {
                    this.state.isediting ?
                        (this.state.isZoombooking ? <EditZoomBooking sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback2} isregistrants={this.state.isregistrants} /> :
                            this.state.isbooking ?
                                <EditBooking sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback}/>
                                :
                                <EditCarpark sb={this.state.selectedBooking} isEdit={this.state.iseditop} backbtn={this.editback1}/>)
                        :
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                <Radio.Group value={this.state.radiooption} buttonStyle="solid" onChange={this.onRadioChange}>
                                    <Radio.Button value="a">{strings.current}</Radio.Button>
                                    <Radio.Button value="b">{strings.history}</Radio.Button>
                                </Radio.Group>
                            </div>
                            <div style={{ marginTop: 20, marginLeft: 10 }}><span style={{ fontSize: 15, fontWeight: 500, color: '#455773' }}>{strings.meetingroom}</span></div>
                            <div style={{ marginTop: 10 }}>
                                <div className="table-operations">
                                </div>
                                <Table style={{ marginTop: 12, backgroundColor: '#fff' }} columns={this.columns} loading={this.state.loading} pagination={{ pageSize: 5 }} dataSource={this.state.roomdata} />
                                <div className="CountOfData">
                                    <span className="CountText">{strings.totalcount} {this.state.count}</span>
                                </div>
                            </div>
                            <div style={{ marginTop: 40, marginLeft: 10 }}><span style={{ fontSize: 15, fontWeight: 500, color: '#455773' }}>{strings.carpark}</span></div>
                            <div style={{ marginTop: 10 }}>
                                <div className="table-operations">
                                </div>
                                <Table style={{ marginTop: 12, backgroundColor: '#fff' }} columns={this.columns1} loading={this.state.loading1} pagination={{ pageSize: 5 }} dataSource={this.state.parkingdata} />
                                <div className="CountOfData">
                                    <span className="CountText">{strings.totalcount} {this.state.count1}</span>
                                </div>
                            </div>
                            <div style={{ marginTop: 40, marginLeft: 10 }}><span style={{ fontSize: 15, fontWeight: 500, color: '#455773' }}>{strings.zoom}</span></div>
                            <div style={{ marginTop: 10 }}>
                                <div className="table-operations">
                                </div>
                                <Table style={{ marginTop: 12, backgroundColor: '#fff' }} columns={this.columns2} loading={this.state.loading1} pagination={{ pageSize: 5 }} dataSource={this.state.zoomdata} />
                                <div className="CountOfData">
                                    <span className="CountText">{strings.totalcount} {this.state.count2}</span>
                                </div>
                            </div>
                            <Modal
                                title={strings.searchuser}
                                visible={this.state.showmodel}
                                onOk={this.handleOk}
                                onCancel={() => this.setState({ showmodel: false })}
                                footer={[

                                    <Button key="submit" className='subscribe1' type="danger" onClick={this.handleOk} disabled={this.state.istransfering}>
                                        {strings.transfer}
                                    </Button>,
                                ]}
                            >
                                <div style={{ paddingTop: 5 }}>
                                    <Row>
                                        <span style={{ color: '#ff7f27', fontSize: 14 }}>{strings.plsincludeslashinsearch}</span>
                                    </Row>
                                    <Row type="flex" style={{ marginTop: 15 }}>
                                        <Col span={6}>{strings.postname1}</Col>
                                        <Col span={18}>
                                            <Form>
                                                <Row type="flex">
                                                    <Col span={17}>
                                                        <Input value={this.state.searchstr} placeholder={strings.enter3char} onChange={e => this.setState({ searchstr: e.target.value })} />
                                                    </Col>
                                                    <Col span={6}>
                                                        <Button type="danger" htmlType="submit" className='subscribe1' style={{ marginLeft: 15, marginTop: 0 }} disabled={this.state.issearching} loading={this.state.issearching} onClick={(e) => this.handleSearch(e)}>
                                                            {strings.search}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>                                            
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{ marginTop: 15 }}>
                                        <Col span={6}>{strings.user}</Col>
                                        <Col span={18}>
                                            <Select defaultValue="0" style={{ width: '100%' }} onChange={this.handleselectChange}>
                                                <Option value="0">{strings.pleaseselect}</Option>
                                                {this.state.approvers.map(d => <Option key={d.id}>{d.name} ({d.post})</Option>)}

                                            </Select>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal>
                            <Modal
                                title={strings.alert}
                                visible={this.state.showalertmodel}
                                onCancel={() => this.setState({ showalertmodel: false })}
                                footer={[
                                    <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); }}>
                                        {strings.ok}
                                    </Button>,
                                ]}
                            >
                                <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                                <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                            </Modal>
                            <Modal
                                title={strings.startmeeting}
                                visible={this.state.showStartMeetingModal}
                                onCancel={() => this.setState({ showStartMeetingModal: false })}
                                className={'bookingsuccessmodel1'}
                                footer={[
                                    <Button key="submit" className='subscribe1' type="danger" onClick={() => this.redirectToMeetingUrl()}>
                                        {strings.start}
                                    </Button>,
                                ]}
                            >
                                <span className="TitleOfTheBox1">{strings.startmeetingnote1}</span><br />
                                <span className="TitleOfTheBox1">{strings.startmeetingnote2}</span><br />
                                <span className="TitleOfTheBox1">&emsp;{strings.hostemailaddress}: {this.state.selectedMeetingemail}</span><br />
                                <span className="TitleOfTheBox1">&emsp;{strings.hostpassword}: {this.state.selectedMeetingpassword}</span>
                            </Modal>
                            <Modal
                            title={strings.shortenextendmeeting}
                            visible={this.state.isShortenModel}
                            onCancel={() => this.setState({ isShortenModel: false })}
                            footer={[
                                <Button key="submit" className='subscribe1' type="danger" onClick={() =>this.extendBooking()}>
                                    {strings.submit}
                                </Button>,
                                <Button onClick={() =>this.setState({ isShortenModel: false })}>
                                    {strings.cancel1}
                                </Button>,
                            ]}>
                                <Row>
                                    <Col span={24}><span className="TitleOfTheBox1">{strings.selectendtime}</span></Col>
                                    {selectedBooking && this.state.availableTimeslots.map((t,i)=><Col span={3} key={i} onClick={()=>this.setState({selectedTimeslot:t})} className={t===this.state.selectedTimeslot?'shortenextendtimeselected':'shortenextendtime'}>{t}</Col>)}
                                    {/* <Col span={6}>
                                        <span className="TitleOfTheBox" style={{fontSize:14}}>{strings.endtime}</span>
                                    </Col>
                                    <Col span={18}>
                                        {selectedBooking && <DatePicker disabled value={moment.unix((selectedBooking.endTime / 1000))} format={'YYYY-MM-DD'}/>}
                                        {selectedBooking && <TimePicker style={{marginLeft:10}} disabledHours={this.disabledHours} disabledMinutes={this.disabledMinutes} hideDisabledOptions value={moment.unix((selectedBooking.endTime / 1000))} onChange={this.onEtChange}  format={'HH:mm'} minuteStep={15}/>} 
                                    </Col>*/}
                                </Row>                                
                            </Modal>
                        </div>
                }
            </div>
        );
    }
}
export default MyBooking;

