import React, { Component } from 'react';
import fetch from './FetchWithTimeout';
import { Input, Row, Col, Table, Select, Tag, List, Radio, Button, Popconfirm, message, Modal, Form, DatePicker, Checkbox } from "antd";
import TableDragSelect from "react-table-drag-select";
import moment from 'moment';
import { ApiHeader, strings, apiurl, RESOURCE_TYPES } from './data';
import "react-table-drag-select/style.css";
import './App.css';
import './drag.css';
const timelines = ['8:30AM', '8:45AM',
    '9AM', '9:15AM', '9:30AM', '9:45AM', '10AM', '10:15AM', '10:30AM', '10:45AM', '11AM', '11:15AM', '11:30AM', '11:45AM',
    '12PM', '12:15PM', '12:30PM', '12:45PM', '1PM', '1:15PM', '1:30PM', '1:45PM', '2PM', '2:15PM', '2:30PM', '2:45PM',
    '3PM', '3:15PM', '3:30PM', '3:45PM', '4PM', '4:15PM', '4:30PM', '4:45PM', '5PM', '5:15PM', '5:30PM', '5:45PM',
    '6PM', '6:15PM', '6:30PM', '6:45PM', '7PM', '7:15PM', '7:30PM', '7:45PM', '8PM', '8:15PM', '8:30PM', '8:45PM',
    '9PM'];
const timelines1 = ['08:30', '08:45',
    '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
    '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
    '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45',
    '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
    '21:00'];
const Search = Input.Search;
var locale = 'EN';
const Option = Select.Option;
class AdminBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemData: null,
            loading: false,
            triggerReload: false,
            showalertmodel: false,
            alertmessage: "",
            tabledata: [],
            timeValue: null,
            contactPhone1: "",
            contactPerson1: "",
            contactPhone2: "",
            contactPerson2: "",
            isReminder:false,
            remark: "",
            chairman: "",
            dateValue:null,
            resourceType: RESOURCE_TYPES.MEETING_ROOM,
            subject: "",
            selectedTime: '',
            loading3: false,
            subjecterr: false,
            cp1err: false,
            cph1err: false,
            chairmanerr: false,
            bid: 0,
            isediting: false,
            iseditop: true,
            selectedBooking: null,
            showmodel: false,
            chairmanId: 0,
            issearching: false,
            searchstr: '',
            approvers: [],
            timeerr:false,
            startTime:0,
            endTime:0,
            trs:[],
            resourceId:0,
            rooms:[],
            cells: [
                [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                    false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                    false, false, false, false, false, false, false, false, false, false, false, false, false],
            ],
            cellstemp: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false, false, false, false],
        }
        this.columns = [
            {
                title: strings.date,
                dataIndex: 'date',
                key: 'date',
                width: '20%',
                render: (a, b) => (a.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm'))

            }, {
                title: strings.subject,
                dataIndex: 'subject',
                key: 'subject',
                width: '10%',
                render: (val, record) => (<p className='tablelongtext'>{val}</p>),
                sorter: (a, b) => a.subject.localeCompare(b.subject, 'en', { numeric: true }),
            }, {
                title: strings.state,
                dataIndex: 'state',
                key: 'state',
                width: '10%',
                sorter: (a, b) => a.state.localeCompare(b.state, 'en', { numeric: true }),
                render: (a, b) => ((a == "booked" || a == "pending_accept" || a == "confirmed") ? strings.booked : strings.completed)
            }, {
                title: strings.location,
                dataIndex: 'location',
                key: 'location',
                width: '15%',
                sorter: (a, b) => a.location.localeCompare(b.location, 'en', { numeric: true }),
                render: (a, b) => (a.replace("()", ""))
            }, {
                title: strings.action,
                key: 'tags',
                dataIndex: 'tags',
                render: (tags, record) => (
                    <span>
                        {tags.map(tag => (tag != strings.cancel1 ? <Tag color={'#455773'} style={{ marginTop: 5 }} key={tag} onClick={() => this.ontagclick({ tag }, record)}>{tag.toUpperCase()}</Tag> :
                            <Popconfirm title={strings.areyousure} okText={strings.confirm} cancelText={strings.cancel1} onConfirm={() => this.ontagclick({ tag }, record)}>
                                <Tag color={'#455773'} key={tag} style={{ marginTop: 5 }}>{tag.toUpperCase()}</Tag>
                            </Popconfirm>
                        ))}
                    </span>
                ),
            }
        ];
    }

    componentDidMount() {
        ApiHeader.userId = sessionStorage.getItem("userId");
        var resource = this.props.sd.resource;
        var isDtd = sessionStorage.getItem("isDtd");
        var rooms = [];
        for(var i=0; i<resource.length; i++){
            if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType && (resource[i].isDtd == null || resource[i].isDtd === 'N')){          
            rooms.push({name:resource[i].nameEN,id:resource[i].id,capacity:resource[i].capacity});
            }              
            else if(RESOURCE_TYPES.MEETING_ROOM === resource[i].resourceType && resource[i].isDtd === 'Y' && isDtd === 'Y'){
            rooms.push({name:resource[i].nameEN,id:resource[i].id,capacity:resource[i].capacity});          
            }              
        }
        rooms.sort((a, b) => a.name.localeCompare(b.name)); 
        this.setState({ systemData: this.props.sd, selectedUser: this.props.su, rooms:rooms  });
        this.searchBooking(this.props.su.id);
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.su.id != this.state.selectedUser.id) {
            this.setState({ selectedUser: nextProps.su })
            this.searchBooking(nextProps.su.id);
        }
    }

    componentWillUnmount() {

    }


    ontagclick = (tag, b) => {
        if (tag.tag == strings.cancel1) {
            this.removeBooking(b.id, b.key);
        } else if (tag.tag == strings.edit) {
            console.log(b.date);
            var dateval = moment(b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]);
            this.setState({
                isediting: true,
                iseditop: true,
                contactPhone1: b.firstcontact.split('(')[1] != null ? b.firstcontact.split('(')[1].slice(0, -1) : '',
                contactPerson1: b.firstcontact.split('(')[0] != null ? b.firstcontact.split('(')[0] : '',
                contactPhone2: b.secondcontact.split('(')[1] != null ? b.secondcontact.split('(')[1].slice(0, -1) : '',
                contactPerson2: b.secondcontact.split('(')[0] != null ? b.secondcontact.split('(')[0] : '',
                remark: b.remark != null ? b.remark : '',
                chairman: b.chairman != null ? b.chairman : '',
                subject: b.subject != null ? b.subject : '',
                selectedBooking: b,
                bid: b.id,
                isReminder: b.isReminder==='Y'?true:false,
                resources: [b.resource],
                resourceId:b.resource.id,
                startTime:b.startTime,
                endTime:b.endTime,
                selectedTime: b.date.split(" ")[1],
                cdatevalue: dateval,
                dateValue: dateval
            })

            var isstart = false;
            var times = b.date.split(" ")[1].split('-');
            var t1 = times[0];
            if (t1.startsWith('0')) {
                t1 = t1.slice(1);
            }
            t1 = t1.replace(':00', '');
            var t2 = times[1];
            if (t2.startsWith('0')) {
                t2 = t2.slice(1);
            }
            t2 = t2.replace(':00', '');
            var cells = this.state.cells;
            for (var i = 0; i < timelines.length; i++) {

                if (timelines[i] === t1 || isstart == true) {
                    if (timelines[i] === t2) {
                        // break;
                        isstart = false;
                    }
                    else {
                        cells[0][i] = true;
                        isstart = true;
                    }
                } else {
                    cells[0][i] = false;
                }
            }
            this.setState({ cells: cells, cellstemp: cells[0] });
            this.getBookingForDay(moment(dateval).valueOf(),b.resource.id);
            
        } else if (tag.tag == strings.view) {
            var dateval = moment(b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]);
            this.setState({
                isediting: true,
                iseditop: false,
                contactPhone1: b.firstcontact.split('(')[1] != null ? b.firstcontact.split('(')[1].slice(0, -1) : '',
                contactPerson1: b.firstcontact.split('(')[0] != null ? b.firstcontact.split('(')[0] : '',
                contactPhone2: b.secondcontact.split('(')[1] != null ? b.secondcontact.split('(')[1].slice(0, -1) : '',
                contactPerson2: b.secondcontact.split('(')[0] != null ? b.secondcontact.split('(')[0] : '',
                remark: b.remark != null ? b.remark : '',
                chairman: b.chairman != null ? b.chairman : '',
                subject: b.subject != null ? b.subject : '',
                selectedBooking: b,
                isReminder: b.isReminder==='Y'?true:false,
                bid: b.id,
                resources: [b.resource],
                startTime:b.startTime,
                endTime:b.endTime,
                selectedTime: b.date.split(" ")[1],
                cdatevalue: dateval,
                dateValue: dateval
            })

            var isstart = false;
            var times = b.date.split(" ")[1].split('-');
            var t1 = times[0];
            if (t1.startsWith('0')) {
                t1 = t1.slice(1);
            }
            t1 = t1.replace(':00', '');
            var t2 = times[1];
            if (t2.startsWith('0')) {
                t2 = t2.slice(1);
            }
            t2 = t2.replace(':00', '');
            var cells = this.state.cells;
            for (var i = 0; i < timelines.length; i++) {

                if (timelines[i] === t1 || isstart == true) {
                    if (timelines[i] === t2) {
                        isstart = false;
                    } else {
                        cells[0][i] = true;
                        isstart = true;
                    }

                } else {
                    cells[0][i] = false;
                }
            }
            this.setState({ cells: cells, cellstemp: cells[0] });
            this.getBookingForDay(moment(dateval).valueOf(),b.resource.id);
        }

    }

    modifyBooking = () => {
        this.setState({ loading: true });
        var rdata = {};
        var isvalid = true;

        if (this.state.startTime == 0 || this.state.endTime == 0) {
            this.setState({ timeerr: true });
            isvalid = false;
        } else {
            if (this.state.startTime <= new Date().getTime()) {
                this.setState({ alertmessage: strings.timeslorerr1, showalertmodel: true, loading: false })
                return;
            }
            if (sessionStorage.getItem("userrole") == 'user') {
                var newdate = new Date();
                newdate.setHours(23);
                newdate.setMinutes(59);
                newdate.setSeconds(59);
                newdate.setMilliseconds(999);
                if (this.state.startTime > newdate.getTime() + (1000 * 60 * 60 * 24 * 365 * 2) + (1000 * 60 * 60 * 24)) {
                this.setState({ alertmessage: strings.timeslorerr2, showalertmodel: true, loading: false })
                return;
                }
            }
            
            rdata.startTime = this.state.startTime;
            rdata.endTime = this.state.endTime;
        }
        console.log(rdata);

        if (this.state.contactPhone1 == "") {
            this.setState({ cph1err: true });
            isvalid = false;
        } else {

            if (this.state.contactPhone1.length <= 45) {
                rdata.contactPhone = this.state.contactPhone1;
            } else {
                message.warning(strings.contactphonemaxlength)
                isvalid = false;
            }
        }

        if (this.state.contactPerson1 == "") {
            this.setState({ cp1err: true });
            isvalid = false;
        } else {

            if (this.state.contactPerson1.length <= 100) {
                rdata.contactPerson = this.state.contactPerson1;
            } else {
                message.warning(strings.contactpersonmaxlength)
                isvalid = false;
            }
        }

        if (this.state.contactPhone2.length <= 45) {
            rdata.contactPhone1 = this.state.contactPhone2;
        } else {
            message.warning(strings.contactphonemaxlength)
            isvalid = false;
        }

        if (this.state.contactPerson2.length <= 100) {
            rdata.contactPerson1 = this.state.contactPerson2;
        } else {
            message.warning(strings.contactpersonmaxlength)
            isvalid = false;
        }

        if (this.state.remark.length <= 500) {
            rdata.remark = this.state.remark;
        } else {
            message.warning(strings.remarkmaxlength)
            isvalid = false;
        }

        if (this.state.subject == "") {
            this.setState({ subjecterr: true });
            isvalid = false;
        } else {

            if (this.state.subject.length <= 100) {
                rdata.subject = this.state.subject;
            } else {
                message.warning(strings.subjectmaxlength)
                isvalid = false;
            }
        }
        if (this.state.chairman == "") {
            this.setState({ chairmanerr: true });
            isvalid = false;
        } else {
            rdata.chairman = this.state.chairman;
        }
        if (this.state.chairmanId != 0)
            rdata.chairmanId = this.state.chairmanId;

        rdata.isReminder = this.state.isReminder?'Y':'N';
        rdata.id = this.state.bid;


        if (isvalid == false) {
            this.setState({ loading: false })
            return;
        }
        fetch(apiurl + 'booking/edit', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                this.setState({ loading: false })
                if (data.code == "1") {
                    this.setState({ alertmessage: strings.updatesuccess, showalertmodel: true, isediting: false, iseditop: true })
                    this.searchBooking(this.state.selectedUser.id);
                } else {
                    this.setState({ alertmessage: strings.errorcode + "" + data.error_code, showalertmodel: true })
                }
            })
            .catch(e => console.warn(e))
    }


    clearScreen = () => {
        var b = this.state.selectedBooking;
        this.setState({
            contactPhone1: b.firstcontact.split('(')[1] != null ? b.firstcontact.split('(')[1].slice(0, -1) : '',
            contactPerson1: b.firstcontact.split('(')[0] != null ? b.firstcontact.split('(')[0] : '',
            contactPhone2: b.secondcontact.split('(')[1] != null ? b.secondcontact.split('(')[1].slice(0, -1) : '',
            contactPerson2: b.secondcontact.split('(')[0] != null ? b.secondcontact.split('(')[0] : '',
            remark: b.remark != null ? b.remark : '',
            chairman: b.chairman != null ? b.chairman : '',
            subject: b.subject != null ? b.subject : '',
            startTime:b.startTime,
            isReminder: b.isReminder==='Y'?true:false,
            endTime:b.endTime,
            cdatevalue: moment(b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2]),
            dateValue: moment(b.date.split(" ")[0].split("-")[0] + "-" + b.date.split(" ")[0].split("-")[1] + "-" + b.date.split(" ")[0].split("-")[2])
        })


        var isstart = false;
        var times = b.date.split(" ")[1].split('-');
        var t1 = times[0];
        if (t1.startsWith('0')) {
            t1 = t1.slice(1);
        }
        t1 = t1.replace(':00', '');
        var t2 = times[1];
        if (t2.startsWith('0')) {
            t2 = t2.slice(1);
        }
        t2 = t2.replace(':00', '');
        for (var i = 0; i < timelines.length; i++) {
            if (timelines[i] === t1 || isstart == true) {
                if (timelines[i] === t2) {
                    break;
                }
                var cells = this.state.cells;
                cells[0][i] = true;
                this.setState({ cells: cells, cellstemp: cells[0] });
                isstart = true;
            }
        }
    }


    searchBooking = (id) => {
        this.setState({ loading: true })
        var rdata = {};
        rdata.resourceType = RESOURCE_TYPES.MEETING_ROOM;
        rdata.state = ['booked', 'pending_accept', 'confirmed'];
        rdata.userId = id;

        fetch(apiurl + 'booking/list', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var bss = data.data.bookings;
                    var bs = [];
                    for (var i = 0; i < bss.length; i++) {
                        bs = bs.concat(bss[i].booking)
                    }
                    var bs1 = [];
                    var resource = this.state.systemData.resource;
                    for (var i = 0; i < bs.length; i++) {
                        var b1 = {};
                        b1.key = i;
                        b1.id = bs[i].id;
                        var ds = moment.unix((bs[i].startTime / 1000)).format("YYYY-MM-DD hh:mmA") + "-" + moment.unix((bs[i].endTime / 1000)).format("hh:mmA");
                        b1.startTime = bs[i].startTime;
                        b1.endTime = bs[i].endTime;
                        b1.date = ds;
                        b1.tags = [strings.view, strings.edit, strings.cancel1];
                        b1.firstcontact = bs[i].contactPerson1 + '(' + bs[i].contactPhone1 + ')';
                        b1.secondcontact = bs[i].contactPerson2 + '(' + bs[i].contactPhone2 + ')';
                        b1.subject = bs[i].subject;
                        b1.remark = bs[i].remark;
                        b1.state = bs[i].state;
                        b1.chairman = bs[i].chairman;
                        b1.groupName = bs[i].groupName;
                        b1.isReminder = bs[i].isReminder;
                        for (var j = 0; j < resource.length; j++) {
                            if (resource[j].id === bs[i].resourceId) {
                                if (locale === 'EN') {
                                    b1.location = resource[j].locationEN.split(',')[0] + " " + resource[j].locationEN.split(',')[1];
                                } else {
                                    b1.location = resource[j].locationZH.split(',')[0] + " " + resource[j].locationZH.split(',')[1];
                                }
                                b1.resource = resource[j];
                                break;
                            }
                        }
                        bs1.push(b1);

                    }
                    this.setState({ tabledata: bs1 });


                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }

    removeBooking = (id, key) => {
        var rdata = {};
        rdata.bookingId = id;
        fetch(apiurl + 'booking/admincancel', {
            headers: ApiHeader,
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(rdata),
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong ...');
            }
        })
            .then(data => {
                if (data.code == "1") {

                    var data = this.state.tabledata;
                    data.splice(key, 1);
                    this.setState({ tabledata: data, alertmessage: strings.bookingcanclled, showalertmodel: true, isediting: false, iseditop: true })
                } else {
                    this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true })
                }
                this.setState({ loading: false })
            })
            .catch(e => console.warn(e))
    }

    findErrorMessage = (msg) => {
        return strings.errorcodes[msg.split(' : ')[1]] != null ? strings.errorcodes[msg.split(' : ')[1]] : msg;
    }

    searchapprover = () => {
        this.setState({ showmodel: true })
    }

    handleOk = () => {
        this.setState({ showmodel: false })
    }


    handleselectChange = (val) => {
        if (val != '0')
            this.setState({ chairmanId: parseInt(val.split('-')[0]), chairman: val.split('-')[1] })
    }

    getBookingForDay=(d,rid)=>{
        var rdata = {};
        rdata.fromDate = moment(d).format('YYYYMMDD');
        rdata.toDate = moment(d).format('YYYYMMDD');
        rdata.state = ['booked','pending_accept','confirmed']
        rdata.resourceType = RESOURCE_TYPES.MEETING_ROOM;
        fetch(apiurl+'booking/list', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
          }).then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error('Something went wrong ...');
                }
              })
              .then(data =>{
                if(data.code == "1"){
                  var bookings = [];
                  if(data.data.bookings.length>0){
                    bookings = data.data.bookings[0].booking;
                  }
                  var rooms = this.state.rooms;
                  var trs = [];
                  for(var i=0;i<rooms.length; i++){
                      if(rooms[i].id === rid){                  
                        var tr = {};
                        tr.id = rooms[i].id;
                        tr.rvalue = rooms[i].name + "\n("+strings.cap+rooms[i].capacity+")";
                        var tds = [];
                        var times = [];
                        var bids = [];
                        for(var b=0; b<bookings.length;b++){
                            if(bookings[b].resourceId == rooms[i].id){
                                bookings[b].room = rooms[i].name;
                                var ds = moment.unix((bookings[b].startTime/1000)).format("hh:mmA");
                                if(ds.startsWith('0')){
                                    ds = ds.slice( 1 );
                                }
                                ds = ds.replace(':00','');
                                times.push(ds);
                                bids.push(bookings[b].id);
                                var st = bookings[b].startTime/1000;
                                var et = bookings[b].endTime/1000;
                                
                                while(st<(et-(15*60))){
                                    st = st + (15*60);
                                    var ds1 = moment.unix((st)).format("hh:mmA");
                                    if(ds1.startsWith('0')){
                                        ds1 = ds1.slice( 1 );
                                    }
                                    ds1 = ds1.replace(':00','');
                                    times.push(ds1);
                                    bids.push(bookings[b].id);
                                }
                            }
                        }
                        var isstart = false;
                        for(var j=0;j<timelines.length-1;j++){
                            var datestr = this.state.dateValue+' '+timelines1[j]
                            var cdate = moment(datestr,'YYYY-MM-DD HH:mm');
                            if(cdate.valueOf()<=moment().valueOf()){
                              var td = {}
                              td.disabled=true;
                              td.isbooking=true;
                              td.id=rooms[i].id+"-"+j+"-"+bids[index];
                              td.timevalue=timelines[j];
                              tds.push(td);
                            }else{
                              var index = times.indexOf(timelines[j]);
                              if(index==-1){
                                  var td = {};
                                  td.disabled=false;
                                  td.isbooking=true;
                                  td.id=rooms[i].id+"-"+j+"-0";
                                  td.timevalue=timelines[j];
                                  tds.push(td);
                              }else{
                                  var td = {}
                                  td.disabled=true;
                                  td.isbooking=true;
                                  td.id=rooms[i].id+"-"+j+"-"+bids[index];
                                  td.timevalue=timelines[j];
                                  tds.push(td);
                              }
                            }
                                                        
                        }
                        tr.tds = tds;
                        trs.push(tr);                        
                  }
                }
                  this.setState({trs:trs})
                  this.getMaintenaceForDay(d,trs,rid);
                
                }else{
                  this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
                }
                this.setState({loading:false}) 
          })
          .catch(e => {
            this.setState({loading:true});
            console.warn(e)
          })
    }
    
    getMaintenaceForDay=(d,trs,rid)=>{
        var rdata = {};
        var dval = moment(d).format('YYYYMMDD');
        rdata.fromDate = dval;
        rdata.toDate = dval;
        rdata.type = RESOURCE_TYPES.MEETING_ROOM;
        fetch(apiurl+'maintenance/listtoday', {
            headers: ApiHeader,
            method: 'POST', 
            credentials: 'same-origin',  
            body: JSON.stringify(rdata),         
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data =>{
            if(data.code == "1"){
                var bookings = data.data.resourceMaintenance;
                var rooms1 = this.state.rooms;
                var rooms = rooms1.filter((r)=>r.id === rid);
                for(var i=0;i<rooms.length; i++){                  
                if(rooms[i].id === rid){
                    var times = [];
                    var bids = [];
                    for(var b=0; b<bookings.length;b++){
                        if(bookings[b].resourceId == rooms[i].id){
                            bookings[b].room = rooms[i].name;                            
                            var sd = parseInt(moment.unix((bookings[b].startTime/1000)).format("YYYYMMDD"));                              
                            var ed = parseInt(moment.unix((bookings[b].endTime/1000)).format("YYYYMMDD"));                            
                            var ds='',st=0,et=0;
                            if(sd != dval){     
                                st = moment(dval+" 7:00","YYYYMMDD hh:mm").unix();
                                et = bookings[b].endTime/1000;
                                ds = moment.unix(st).format("hh:mmA");                               
                            }
                            else if(ed != dval){                                
                                st = bookings[b].startTime/1000;
                                et = moment(dval+" 22:00","YYYYMMDD hh:mm").unix();                                
                                ds = moment.unix((bookings[b].startTime/1000)).format("hh:mmA");                                
                            }else{
                                ds = moment.unix((bookings[b].startTime/1000)).format("hh:mmA");
                                st = bookings[b].startTime/1000;
                                et = bookings[b].endTime/1000;
                            }
                            if(ds.startsWith('0')){
                                ds = ds.slice( 1 );
                            }
                            ds = ds.replace(':00','');
                            times.push(ds);
                            bids.push(bookings[b].id);                            
                            while(st<(et-(15*60))){
                                st = st + (15*60);
                                var ds1 = moment.unix((st)).format("hh:mmA");
                                if(ds1.startsWith('0')){
                                    ds1 = ds1.slice( 1 );
                                }
                                ds1 = ds1.replace(':00','');
                                times.push(ds1);
                                bids.push(bookings[b].id);
                            }
                        }
                    }
                    
                    for(var jj=0;jj<times.length;jj++){
                        
                        var j = timelines.indexOf(times[jj]);
                        if(j != -1){  
                            if(trs[i].tds[j] != null) {
                            trs[i].tds[j].disabled=true;
                            trs[i].tds[j].isbooking=false;
                            trs[i].tds[j].id=rooms[i].id+"-"+j+"-"+bids[jj];
                            }                            
                        }                            
                    }                        
                }
                
                }
                this.setState({trs:trs})
            }else{
                this.setState({alertmessage:strings.errorcode+data.error_code,showalertmodel:true})
            }
            this.setState({loading:false}) 
        })
        .catch(e => {
        this.setState({loading:false});
        console.warn(e)
        })
    }

    handleSearch = (e) => {
        if (!this.state.issearching && this.state.searchstr.length > 1) {
            var val = this.state.searchstr;
            this.setState({ issearching: true });
            var rdata = {};
            rdata.name = val;
            fetch(apiurl + 'user/search1', {
                headers: ApiHeader,
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(rdata),
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
                .then(data => {
                    if (data.code == "1") {
                        var users = data.data.user;
                        this.setState({ approvers: users, issearching: false });
                    } else {
                        this.setState({ alertmessage: strings.errorcode + data.error_code, showalertmodel: true, issearching: false })
                    }
                    this.setState({ issearching: false })
                })
                .catch(e => { console.warn(e); this.setState({ issearching: false }) })
        } else {
            this.setState({ alertmessage: strings.enter3char, showalertmodel: true })
        }
    }

    rendertd = () => {
        var res = [];
        for (var i = 0; i < 50; i++) {
            if (i < 2) {
                res.push(<td key={i} className='dragselecttimeline1' ></td>);
            } else {
                res.push(<td key={i} className='dragselecttimeline' ></td>);
            }
        }
        return res;
    }

    onSelect = (value) => {
        this.setState({ cdatevalue: moment(value.format('YYYY-MM-DD')), dateValue: moment(value.format('YYYY-MM-DD')), selectedTime: '' })
        this.handleTimelineChange(this.state.cells)
        this.getBookingForDay(value.valueOf(),this.state.resourceId);
    }
    
    handleTimelineChange = (cells) => {
        var celltemp = this.state.cellstemp;
        for (var i = 0; i < celltemp.length; i++) {
          if (celltemp[i] == true && cells[0][i] == true) {
            cells[0][i] = false;
          }
        }
        var times = [];
        var times1 = [];
        var index = 0;
        for (var i = 0; i < cells[0].length; i++) {
          if (cells[0][i] == true) {
            if (times.length == 0) {
              index = i;
              times.push(timelines[i]);
              times1.push((i * 900000) + 30600000);
            } else {
              times1.push(((i + 1) * 900000) + 30600000);
              times.push(timelines[i + 1]);
            }
          }
        }
        if (times.length == 1) {
          times.push(timelines[index + 1]);
          times1.push(((index + 1) * 900000) + 30600000);
        }
        this.setState({ cells: cells, cellstemp: cells[0], });
        var datestr = this.state.dateValue.format('YYYY-MM-DD') + ' 00:00:00';
        var ts = moment(datestr, "YYYY-MM-DD HH:mm:SS").valueOf();
        if (times[0] != null && times[times.length - 1] != null)
          this.setState({ selectedTime: times[0] + " - " + times[times.length - 1], startTime: parseInt(ts + times1[0]), endTime: parseInt(ts + times1[times1.length - 1]) });
        else
          this.setState({ selectedTime: '', startTime: '', endTime: '' });
    }

    onIsReminderChange=(e)=>{
        this.setState({isReminder:e.target.checked});
    }

    rendertd1=(tds)=>{
        var rds = [];
        for(var i=0; i<tds.length; i++){    
          var d = tds[i];
          if (i < 2) {
            rds.push(
              d.isbooking?
                d.disabled?
                  <td key={d.id} className='dragselecttimeline1' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderBottom:'0px',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',backgroundColor: '#dedede'}}></td>
                  :<td key={d.id} className='dragselecttimeline1'style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}}></td>:
                d.disabled?
                  <td key={d.id} className='dragselecttimeline1' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderBottom:'0px',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',backgroundColor: '#CECAAE'}}></td>
                  :<td key={d.id} className='dragselecttimeline1' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}} ></td>  
            );
          }else{
            rds.push(
              d.isbooking?
                d.disabled?
                  <td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',backgroundColor: '#dedede'}} ></td>
                  :<td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}}></td>:
                d.disabled?
                  <td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px',backgroundColor: '#CECAAE'}}></td>
                  :<td key={d.id} className='dragselecttimeline' style={{height:10,border: '0.5px solid rgb(235, 235, 235)',borderRight:i+1===tds.length?'0.5px solid rgb(235, 235, 235)':'0px',borderBottom:'0px'}}></td>  
            );
          }
        };
        return rds;
    }

    render() {
        return (
            <div >
                <Table style={{ backgroundColor: '#fff' }} columns={this.columns} dataSource={this.state.tabledata} />
                <div className="CountOfData">
                    <span className="CountText">{strings.totalcount}{this.state.tabledata.length}</span>
                </div>
                <div className="SecondBox" style={{ marginTop: 20, marginLeft: 0, width: '100%' }} hidden={!this.state.isediting}>
                    <span className="TitleOfTheBox">{strings.editbooking}</span>
                    <div>
                        <Row xs={24} style={{ marginTop: 7 }}>
                            <Col md={4} >
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className="DateText">{strings.date}</span>
                            </Col>
                            <Col md={5} >
                                <div>
                                    <DatePicker size="large" className='bookingDatebox' value={this.state.dateValue} format={'YYYY-MM-DD'} onChange={this.onSelect} disabled={!this.state.isediting}/>
                                </div>
                            </Col>
                            <Col md={3}>

                            </Col>

                            <Col md={12} style={{}}>
                            </Col>
                        </Row>
                        <div style={{ marginTop: 10, height: 90 }}>
                            <table><tbody>
                                <tr className='headertimeline'>
                                    <td >8:30<span>am</span>-9<span>am</span></td>
                                    <td >9<span>am</span>-10<span>am</span></td>
                                    <td >10<span>am</span>-11<span>am</span></td>
                                    <td >11<span>am</span>-12<span>pm</span></td>
                                    <td >12<span>pm</span>-1<span>pm</span></td>
                                    <td >1<span>pm</span>-2<span>pm</span></td>
                                    <td >2<span>pm</span>-3<span>pm</span></td>
                                    <td >3<span>pm</span>-4<span>pm</span></td>
                                    <td >4<span>pm</span>-5<span>pm</span></td>
                                    <td >5<span>pm</span>-6<span>pm</span></td>
                                    <td >6<span>pm</span>-7<span>pm</span></td>
                                    <td >7<span>pm</span>-8<span>pm</span></td>
                                    <td >8<span>pm</span>-9<span>pm</span></td>
                                </tr></tbody>
                            </table>
                            <tr>
                                {this.rendertd1(this.state.trs.length>0?this.state.trs[0].tds:[])}
                            </tr>
                            <TableDragSelect value={this.state.cells} onChange={this.handleTimelineChange}>
                                <tr>
                                    {this.rendertd()}
                                </tr>
                            </TableDragSelect>
                        </div>

                        <Row xs={24} style={{ marginTop: 10 }}>
                            <Col md={4} >
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className={this.state.timeerr ? "DateTextErr" : "DateText"}>{strings.time}</span>
                            </Col>
                            <Col md={20} >
                                <div>
                                    <Input size="large" className='bookingDatebox' value={this.state.selectedTime.replace(new RegExp('AM', 'g'), 'am').replace(new RegExp('PM', 'g'), 'pm')} disabled />

                                </div>
                            </Col>
                        </Row>


                        <Row xs={24} style={{ marginTop: 10 }} >
                            <Col md={4} >
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className={this.state.chairmanerr ? "DateTextErr" : "DateText"}>{strings.chairman}</span>
                            </Col>
                            <Col md={5}>
                                <div>
                                    <Input disabled size="large" className='bookingDatebox' value={this.state.chairman} placeholder={strings.pleasefill+' '+strings.chairman} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="Btnsubscribe">
                                    <Button className="subscribe" type="danger" loading={this.state.loading4} onClick={this.searchapprover} hidden={!this.state.iseditop}>
                                        {strings.search}
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Row xs={24} style={{ marginTop: 10 }} >
                            <Col md={4}>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className={this.state.subjecterr ? "DateTextErr" : "DateText"}>{strings.subject}</span>
                            </Col>
                            <Col md={20}>
                                <div>
                                    <Input disabled={!this.state.iseditop} size="large" style={{ width: 480 }} value={this.state.subject} placeholder={strings.pleasefill+' '+strings.subject} onChange={e => this.setState({ subject: e.target.value })} />
                                </div>
                            </Col>
                        </Row>

                        <Row xs={24} style={{ marginTop: 10 }} >
                            <Col md={4} >
                                <span style={{ marginLeft: 12 }} className="DateText">{strings.remark}</span>
                            </Col>
                            <Col md={20}>
                                <div>
                                    <Input disabled={!this.state.iseditop} size="large" style={{ width: 480 }} value={this.state.remark} placeholder={strings.pleasefill+' '+strings.remark} onChange={e => this.setState({ remark: e.target.value })} />
                                </div>
                            </Col>
                        </Row>

                        <Row xs={24} style={{ marginTop: 10 }} >
                            <Col md={4}>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className={this.state.cp1err ? "DateTextErr" : "DateText"}>{strings.contactperson1}</span>
                            </Col>
                            <Col md={8}>
                                <div>
                                    <Input disabled={!this.state.iseditop} size="large" className='bookingDatebox' value={this.state.contactPerson1} placeholder={strings.pleasefill+' '+strings.contactperson1} onChange={e => this.setState({ contactPerson1: e.target.value })} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <span style={{ color: '#ff1b03', fontWeight: 500, fontSize: 20 }}>*  </span>
                                <span className={this.state.cph1err ? "DateTextErr" : "DateText"}>{strings.contactphone1}</span>
                            </Col>
                            <Col md={8}>
                                <div>
                                    <Input disabled={!this.state.iseditop} size="large" className='bookingDatebox' value={this.state.contactPhone1} placeholder={strings.pleasefill+' '+strings.contactphone1} onChange={e => this.setState({ contactPhone1: e.target.value })} />
                                </div>
                            </Col>
                        </Row>

                        <Row xs={24} style={{ marginTop: 10 }} >
                            <Col md={4}>
                                <span style={{ marginLeft: 12 }} className="DateText">{strings.contactperson2}</span>
                            </Col>
                            <Col md={8}>
                                <div>
                                    <Input disabled={!this.state.iseditop} size="large" className='bookingDatebox' value={this.state.contactPerson2} placeholder={strings.pleasefill+' '+strings.contactperson2} onChange={e => this.setState({ contactPerson2: e.target.value })} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <span style={{ marginLeft: 12 }} className="DateText">{strings.contactphone2}</span>
                            </Col>
                            <Col md={8}>
                                <div>
                                    <Input disabled={!this.state.iseditop} size="large" className='bookingDatebox' value={this.state.contactPhone2} placeholder={strings.pleasefill+' '+strings.contactphone2} onChange={e => this.setState({ contactPhone2: e.target.value })} />
                                </div>
                            </Col>
                        </Row>
                        <Row xs={24} style={{ marginTop: 20, }} >
                            <Col md={4}>
                                <span className="DateText" style={{ marginLeft: 12 }}>{strings.isreminder}</span>
                            </Col>
                            <Col md={1}>
                                <div>
                                <Checkbox disabled={!this.state.iseditop} onChange={(e) => this.onIsReminderChange(e)} style={{ marginLeft: 5 }} checked={this.state.isReminder}>{''}</Checkbox>
                                </div>
                            </Col>
                            <Col md={14}>
                                <span className="LongText2" style={{marginLeft:0}}>{strings.roomremindermsg}</span>
                            </Col>
                        </Row>

                        <div style={{ marginTop: 15, marginBottom: 0 }}>
                            <span>
                                <Button hidden={!this.state.iseditop} type="primary" size='large' style={{ marginRight: '5%' }} loading={this.state.loading} onClick={this.modifyBooking}>
                                    {strings.edit}
                                </Button>
                                <Button hidden={!this.state.iseditop} size='large' onClick={this.clearScreen}>
                                    {strings.clear}
                                </Button>
                            </span>
                        </div>
                    </div>
                </div>
                <Modal
                    title={strings.searchuser}
                    visible={this.state.showmodel}
                    onOk={this.handleOk}
                    onCancel={() => this.setState({ showmodel: false })}
                    footer={[

                        <Button key="submit" className='subscribe1' type="danger" onClick={this.handleOk}>
                            {strings.select}
                        </Button>,
                    ]}
                >
                    <div style={{ paddingTop: 5 }}>
                        <Row>
                            <span style={{ color: '#ff7f27', fontSize: 14 }}>{strings.plsincludeslashinsearch}</span>
                        </Row>
                        <Row type="flex" style={{ marginTop: 15 }}>
                            <Col span={6}>{strings.postname1}</Col>
                            <Col span={18}>
                                <Form>
                                    <Row type="flex">
                                        <Col span={17}>
                                            <Input value={this.state.searchstr} placeholder={strings.enter3char} onChange={e => this.setState({ searchstr: e.target.value })} />
                                        </Col>
                                        <Col span={6}>
                                            <Button type="danger" htmlType="submit" className='subscribe1' style={{ marginLeft: 15, marginTop: 0 }} disabled={this.state.issearching} loading={this.state.issearching} onClick={(e) => this.handleSearch(e)}>
                                                {strings.search}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row type="flex" style={{ marginTop: 15 }}>
                            <Col span={6}>{strings.user}</Col>
                            <Col span={18}>
                                <Select defaultValue="0" style={{ width: '100%' }} onChange={this.handleselectChange}>
                                    <Option value="0">{strings.pleaseselect}</Option>
                                    {this.state.approvers.map(d => <Option key={d.id + '-' + d.name + " (" + d.post + ")"}>{d.name} ({d.post})</Option>)}

                                </Select>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    title={strings.alert}
                    visible={this.state.showalertmodel}
                    onCancel={() => this.setState({ showalertmodel: false })}
                    footer={[
                        <Button key="submit" className='subscribe1' type="danger" onClick={() => { this.setState({ showalertmodel: false }); if (this.state.triggerReload) window.location.reload(); }}>
                            {strings.ok}
                        </Button>,
                    ]}
                >
                    <span className="TitleOfTheBox">{strings.errormessage}</span><br />
                    <span className="TitleOfTheBox1">{this.state.showalertmodel ? this.findErrorMessage(this.state.alertmessage) : ''}</span>
                </Modal>
            </div>
        );
    }
}
export default AdminBooking;

